<template>
  <Layout :selectIndex="4">
    <!--菜单5-->
    <!--轮播图-->
    <carousel />
    <!--展会服务-->
    <div class="rwt-about-area rn-section-gap">
      <div class="container">
        <div style="margin: 0 auto;width: 200px">
          <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
          <h3 style="text-align: center;line-height: 30px;">下载中心</h3>
        </div>
        <div class="row row--15">
          <template>
            <div class="col-lg-11" data-aos="fade-up"
                 style="border-bottom: 1px solid #f0f0f0;margin: 10px 0px;color: #000">
              天津趣宠会参展申请表</div>
            <div class="col-lg-1" data-aos="fade-up" style="border-bottom:1px solid #f0f0f0;margin: 10px 0px;">
              <p style="color: #fff;background: #9618D3;border-radius: 5px;text-align: center;line-height: 30px;" @click="down">立即下载</p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </Layout>
</template>



<script>
import carousel from  "../components/elements/carousel/carousel"
import Layout from "../components/common/Layout";

export default {
  name: 'Download',
  components: {carousel, Layout},
  data() {
    return {
    }
  },
  methods: {
    down() {
      let e = document.createElement('a');
      e.href = '/down/天津趣宠会参展申请表2024.pdf';
      e.setAttribute("target", "_blank")
      document.body.appendChild(e)
      e.click()
      document.body.removeChild(e)
    },
  }
}
</script>
<style>
.selectTab{
  color: #fff;
  background: #9618D3;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #9618D3;
  color: #fff;
}
.el-pagination__total{
  border: 1px solid #f0f0f0;
  background: #f4f4f5;
  padding: 0px 10px;
}
</style>
