<template>
    <Layout :selectIndex="2">
        <!--轮播图-->
        <carousel />
        <!--日程安排-->
        <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <img style="width: 50%;margin: 0px auto; display: block;" src="@/assets/images/img/notice/notice2.png"/>
            </div>
        </div>
    </Layout>
</template>



<script>
    import carousel from  "../components/elements/carousel/carousel";
    import Layout from "../components/common/Layout";
    export default {
        name: 'ExhibitionInformationDetails',
        components: {carousel,Layout },
        data() {
            return {
                informationData:[
                    {
                        index: 1,
                        title: '延期通知',
                    }
                ],
                informationDel: {}
            }
        },
        created() {
            this.informationDel = this.informationData.filter(e => e.index == this.$route.query.index)[0]
        },
        mounted() {
        },
        methods: {
        }
    }
</script>
<style>
    .information p{
        margin: 0px;
        padding: 0px;
        line-height: 40px;
    }
</style>
