<template>
  <div class="rwt-about-area rn-section-gap">
    <div class="container">
      <div style="margin: 0 auto;width: 200px">
        <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
        <h3 style="text-align: center;line-height: 30px;">展会介绍</h3>
      </div>
      <div class="row row--30">
        <div class="col-lg-5">
            <img style="width: 80%;" src="../../../assets/images/img/index/exhibition.png" alt="About Images">
        </div>
        <div class="col-lg-7">
            <p data-aos="fade-up" style="color: #000;line-height: 35px;margin-top: 30px;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“趣宠会”致力于<span style="color: #9618D3;font-weight: bold;font-size: 20px;padding-left: 5px;">打造超有“趣”的宠物产业博览会！</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;近年来，宠物消费赛道不断升级。随着人们生活水平的提高和消费水平的改变，养宠更接近满足马斯洛需求中的情感需求。养宠需求更加多样化和个性化，不仅仅追求基本健康和安全，还要追求品质生活和高端服务。因此，宠物的产品越来越高端，越做越精细化。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“趣宠会”将汇聚宠物全产业链，打造最具特色的创新型宠物产业生态交易平台，在丰富多彩的趣味活动中为用户缔造实质商机！“链接产业生态，聚焦宠物经济，赋能城市发展”！
            </p>
            <router-link to="/ExhibitionOverview">
                <p class="moreA" style="width: 100px;
                    text-align: center;
                    line-height: 40px;
                    border-radius:5px;color: #0f0f11;background: #ccc">查看更多</p>
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AboutOne',
  components: {}
}
</script>
<style>
.moreA:hover{
  color: #fff !important;
  background: #9618D3 !important;
}
</style>
