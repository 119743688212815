<template>
  <Layout :selectIndex="0">
    <!--菜单1-->
    <div class="demoBox">
      <!--AAA首页-->
      <!--轮播图-->
      <carousel/>
      <!--展会介绍-->
      <about-one/>
      <!--展品范围-->
      <overview-three :range="0"/>
      <div  style="margin: 0px auto;width: 130px;">
        <router-link to="/ScopeExhibits">
          <p  class="moreA" style="line-height: 40px;color:#000;border-radius:5px;text-align: center; margin-top: 40px;background: #ccc">
            查看更多
          </p>
        </router-link>
      </div>
      <!--行业资讯-->
      <about-three/>
      <!--合作媒体-->
      <about-four/>
    </div>
  </Layout>
</template>



<script>
import AboutOne from "../components/elements/about/AboutOne";
import AboutThree from "../components/elements/about/AboutThree";
import AboutFour from "../components/elements/about/AboutFour";
import carousel from  "../components/elements/carousel/carousel";
import Layout from "../components/common/Layout";
import OverviewThree from "../components/elements/exhibitionOverview/OverviewThree";

export default {
  name: 'Demo',
  components: {carousel,Layout,AboutOne,AboutThree,AboutFour,OverviewThree },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
