<template>
  <div>
    <div>
      <div>
        <el-button type="primary" @click="doScan">扫一扫</el-button>
      </div>
      <el-dialog
          title="礼品信息"
          :visible.sync="visible"
          destroy-on-close
          width="80%">
          <div>
            <div>{{gift.giftName}}</div>
            <el-image
                style="width: 500px; height: 500px"
                :src="gift.redeemGiftPictures"
                fit="fill"></el-image>
          </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {Api} from "../../utils/api";
import {Message} from "element-ui";
import wx from "weixin-js-sdk";
export default {
  name: 'signMap',//奖品兑换-服务人员扫描页面
  data() {
    return {
      userCount: 0,
      activityId:'',
      loading: true,
      visible: false,
      phone: '',
      gift:{}
    }
  },
  created() {
   this.phone=this.$route.query.phone
   this.getConfig()
  },
  mounted() {
    this.loading=false
    setInterval(()=>{
      if (this.visible){
        this.getResult();
      }
    },3000)
  },
  methods: {

    // 配置信息
    getConfig() {
      let url =  window.location.href.split('#')[0]
      const that = this;
      Api.getJsSdkSign({url: url}).then(res => {
        that.wxConfig(res.timestamp,res.nonceStr,res.signature);
      }).catch(err => {
        console.log('err', err)
      })
    },
    wxConfig(timestamp, nonceStr, signature) {
      wx.config({
        debug: true, // 开启调试模式,
        appId: 'wx2c6e9ff42c5dc410', // 必填，公众号appid
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名
        jsApiList: ['scanQRCode', 'checkJsApi'], // 必填，需要使用的JS接口列表
      });
      wx.ready(() => {
        console.log('配置完成，扫码前准备完成')
      })
      wx.error(function(res) {
        alert('出错了：' + res.errMsg); //wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
      });
    },
    //扫一扫
    doScan() {
      let that=this
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          Api.exchangeGift({ text:result}).then(res => {
            if (res.success){
              that.gift=res.data
              that.visible=true;
            }else {
              Message.error(res.message)
            }
          });
        }
      });
    }
  },
}
</script>


<style scoped>
</style>