<template>
  <div style="height: 100vh; width: 100vw; background-color: #9618D3; display: flex; flex-direction: column; overflow-y: hidden;">
    <div class="container-header">
      <img src="@/assets/images/login/logo.jpg" v-show="hideshow" />
    </div>

    <div style="height: calc(100vh - 100vw * 493 / 1080 - 100vw * 306 / 1080); width: 100vw; display: flex; justify-content: center; align-items: center;">
      <div class="container-body">
        <div class="container-login-title">
          <img src="@/assets/images/login/title.png" />
          <span>专业观众参展申请</span>
        </div>
        <el-form ref="form" :model="form" :rules="rules" label-width="0" class="container-body-form">
          <el-form-item prop="ticketBuyerPhone">
            <div class="container-body-form-item">
              <img src="@/assets/images/login/account.png" />
              <div class="container-body-form-item-right">
                <el-input class="container-body-form-item-input" placeholder="请输入手机号" v-model="form.ticketBuyerPhone"></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="validateCode">
            <div class="container-body-form-item">
              <img src="@/assets/images/login/password.png" />
              <div class="container-body-form-item-right">
                <el-input class="container-body-form-item-input" placeholder="请输入验证码" v-model="form.validateCode"></el-input>
                <el-button class="container-body-form-item-button" :loading="loading" @click="handleSms">{{smsText}}</el-button>
              </div>
            </div>
          </el-form-item>

          <div class="container-body-form-agree">
            <img src="@/assets/images/login/checked.png" @click="checked = !checked" v-if="checked" />
            <img src="@/assets/images/login/unchecked.png" @click="checked = !checked" v-if="!checked" />
            <span @click="toPolicy">已阅读<span>《预约须知》</span>并同意</span>
          </div>

          <el-form-item>
            <el-button class="container-body-form-item-login" type="primary" @click="handleSubmit" :loading="loading">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="container-footer">
      <img src="@/assets/images/login/footer.png" v-show="hideshow" />
    </div>

    <el-dialog :visible.sync="dialogVisible" width="100%" top="0" v-if="dialogVisible">
      <div v-loading="loading">
        <div class="notice-area">
          <div class="title">《预约须知》</div>
          <div class="content">
            <div v-for="item in pList">
              <span v-if="titles.indexOf(item) >= 0" style="font-weight: bold; color: #9618D3">{{item}}</span>
              <span v-else>{{item}}</span>
            </div>

            <el-button class="form-button" type="primary" @click="agreePolicy">同意</el-button>
            <!--        <el-button class="form-button" style="margin-top: 0;" type="danger" @click="goBack(0)">拒绝</el-button>-->
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import {Api} from "@/utils/api";

// 接收预约须知页是否同意
// let previousData = {
//   checked: false,
//   ticketBuyerName: "",
//   validateCode: ""
// };

export default {
  data() {
    return {
      rules: {
        ticketBuyerPhone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern: /^(1[0-9]{10})$/, message: "手机号格式错误", trigger: 'blur'}
        ],
        validateCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
      },
      form: {
        ticketBuyerPhone: "",
        validateCode: ""
      },
      smsText: "发送短信",
      loading: false,
      checked: false,
      docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
      showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
      hideshow: true,  //显示或者隐藏footer

      countdownStart: null, // 倒计时开始时间
      dialogVisible: false,

      pList: [],
      titles: ["预约须知：", "进馆路线：", "免票须知：", "参观时间：", "携宠须知：", "进馆须知：", "售后须知："],
      notice: "",

      ticketSellerInfoId: "0ade924fab0b420ae81a88c6cda00d63152c6447e6a524ef403aec740d25f994",
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   if (from.name === "SpecialPolicy") {
  //     console.log(from.params);
  //     previousData = {
  //       checked: (from.params.checked === 1),
  //       ticketBuyerPhone: from.params.ticketBuyerPhone,
  //       validateCode: from.params.validateCode
  //     };
  //   }
  //   next();
  // },
  watch: {
    //监听显示高度
    showHeight:function() {
      if(this.docmHeight > this.showHeight){
        //隐藏
        this.hideshow=false
      }else{
        //显示
        this.hideshow=true
      }
    }
  },
  // created() {
  //   // console.log("1111111", previousChecked);
  //   this.checked = previousData.checked;
  //   if (previousData.ticketBuyerPhone) {
  //     this.$set(this.form, "ticketBuyerPhone", previousData.ticketBuyerPhone);
  //   }
  //   if (previousData.validateCode) {
  //     this.$set(this.form, "validateCode", previousData.validateCode);
  //   }
  // },
  mounted() {
    let ticketSellerInfoId = this.$route.query.ticketSellerInfoId;
    if (ticketSellerInfoId) {
      this.ticketSellerInfoId = ticketSellerInfoId;
    }

    // console.log("ticketSellerInfoId:", this.ticketSellerInfoId);

    //监听事件
    window.onresize = ()=>{
      return(()=>{
        this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
      })()
    }

    Api.ticketPurchaseNoticeTopic().then(res => {
      this.loading = false;
      this.notice = res.data;
      let pList = [];
      let pList0 = res.data.split("\\n");
      let length = pList0.length;
      for (let i = 0; i < length; i++) {
        pList = [...pList, ...pList0[i].split("\r\n")];
      }
      this.pList = [...this.pList, ...pList];
    }).catch(() => {
      this.loading = false;
    })
  },
  methods: {
    toPolicy() {
      this.dialogVisible = true;
      // this.$router.push("/SpecialPolicy?ticketBuyerPhone=" + this.form.ticketBuyerPhone + "&validateCode=" + this.form.validateCode);
    },
    agreePolicy() {
      this.checked = true;
      this.dialogVisible = false;
    },
    handleSms() {
      if (!this.form.ticketBuyerPhone || !new RegExp(/^(1[0-9]{10})$/).test(this.form.ticketBuyerPhone)) {
        this.$message.error("请输入手机号");
        return;
      }
      if (this.smsText !== "发送短信") {
        return;
      }
      this.loading = true;
      Api.specialSendSms({
        ticketBuyerPhone: this.form.ticketBuyerPhone
      }).then(res => {
        if (res.success) {
          let countdown = 60;
          this.countdownStart = new Date().getTime();
          let interval = setInterval(() => {
            this.loading = false;
            if (countdown >= 1) {
              this.smsText = countdown-- + "s";
            } else {
              clearInterval(interval);
              this.countdownStart = null;
              this.smsText = "发送短信";
            }
          }, 1000)
        } else {
          this.loading = false;
          this.$message.error(res.message);
        }
      })
    },
    handleSubmit() {
      if (!this.checked) {
        this.$message.error("请阅读《预约须知》并同意");
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let form = this.form;
          Api.specialCheckSms({
            phoneNumber: form.ticketBuyerPhone,
            validateCode: form.validateCode
          }).then(res => {
            if (res.success) {
              this.loading = false;
              if (res.data.code === "0") {
                this.$router.push("/SpecialAudit?ticketBuyerPhone=" + form.ticketBuyerPhone + "&ticketSellerInfoId=" + this.ticketSellerInfoId);
              } else if (res.data.code === "1") {
                // this.$confirm('审核中，请耐心等候！', '提示', {
                //   confirmButtonText: '确认',
                //   cancelButtonText: '取消',
                //   type: 'warning',
                //   center: true
                // })
                // this.$router.push("/SpecialAudit?ticketBuyerPhone=" + form.ticketBuyerPhone + "&auditType=" + res.data.code);
                this.$router.push("/SpecialAudit?ticketBuyerPhone=" + form.ticketBuyerPhone + "&ticketSellerInfoId=" + this.ticketSellerInfoId);
              } else if (res.data.code === "2") {
                // this.$confirm('申请已被驳回，请重新申请！', '提示', {
                //   confirmButtonText: '确认',
                //   cancelButtonText: '取消',
                //   type: 'warning',
                //   center: true
                // }).then(() => {
                //   this.$router.push("/SpecialAudit?ticketBuyerPhone=" + form.ticketBuyerPhone);
                // })
                // this.$router.push("/SpecialAudit?ticketBuyerPhone=" + form.ticketBuyerPhone + "&auditType=" + res.data.code);
                this.$router.push("/SpecialAudit?ticketBuyerPhone=" + form.ticketBuyerPhone + "&ticketSellerInfoId=" + this.ticketSellerInfoId);
              } else if (res.data.code === "3") {

                this.$router.push({
                  name: "SpecialCode",
                  params: {
                    purchaseId: res.data.purchaseId
                  }
                })

                // this.$router.push("/SpecialCode?purchaseId=" + res.data.purchaseId);
              }
            } else {
              this.loading = false;
              this.$message.error(res.message);
            }
          }).catch(err => {
            this.loading = false;
          })
        }
      })
    },
  }
}

</script>

<style scoped>

.container-header {
  //position: fixed;
  //top: 10px;
  height: calc(100vw * 493 / 1080);
  width: 100vw;
}

.container-body {
  height: auto;
}
.container-login {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-login-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.container-login-title img {
  width: 50px;
}
.container-body-form {
  margin-top: 40px;
}
.container-body-form /deep/ .el-form-item {
  margin-bottom: 30px;
}
.container-body-form /deep/ .el-form-item__error {
  margin-left: 60px;
  height: 20px;
  line-height: 20px;
}
.container-body-form-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-body-form-item img {
  width: 30px;
  margin-right: 10px;
}
.container-body-form-item-right {
  width: calc(80vw - 50px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: white 2px solid;
  border-radius: 20px;
}
.container-body-form-item-input {
  width: 100%;
}
.container-body-form-item-input /deep/ input {
  border: none;
  color: white;
  font-size: 12px;
}
.container-body-form-item-button {
  background-color: transparent;
  color: white;
  border: none;
  border-left: white 2px solid;
  border-radius: 0;
  width: 8em;
  text-align: center;
  font-size: 12px;
}
/*去掉按钮的loading效果*/
.container-body-form-item-right /deep/ .el-button.is-loading:before {
  background-color: transparent;
}
.container-body-form-agree {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
}
.container-body-form-agree img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.container-body-form-item-login {
  background-color: transparent;
  width: calc(80vw - 50px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: white 2px solid;
  border-radius: 20px;
  margin-left: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}
.container-footer {
  //position: fixed;
  //bottom: 50px;
  width: 90vw;
  margin-left: 5vw;
  height: calc(100vw * 306 / 1080);
}


.notice-area {
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #f0defb;
}
.title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
  text-align: center;
  color: #9618D3;
}
.content {
  padding: 0 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.content-text {
  /* text-indent: 2em; */
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}

.form-button {
  width: 90vw;
  margin: 10px auto 10px auto;
  background-color: #9618D3;
  color: white;
  height: 40px;
  border-radius: 20px;
  border: none;
}

/deep/ .el-dialog {
  margin: 0;
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 0;
}

</style>
