<template>
  <div>
    <div v-if="miniprogram" :style="{backgroundImage: 'url('+require('@/assets/images/activity/exScan.jpg')+')'}" class="button-class">
      <div :style="{backgroundImage: 'url('+require('@/assets/images/activity/button.png')+')',width:'60%',height:'60px'}"
           class="button-class" @click="doScan">
        <div style="font-size: 20px;color: #aa46da;font-family: '微软雅黑'">
          <b>扫 码</b>
        </div>
      </div>
    </div>
    <div v-else style="text-align: center;margin-top: 100px">
      <h3>请使用指定入口打开页面</h3>
    </div>

  </div>
</template>

<script>
import VueQr from 'vue-qr'
import {Api} from "../../utils/api";
import {Message} from "element-ui";
import wx from "weixin-js-sdk";
import {Toast} from "../../utils/toast";
import axios from "axios";
export default {
  name: 'signMap',//集章-展商扫描页面
  components: {
    VueQr
  },
  data() {
    return {
      userCount: 0,
      activityId:'',
      loading: true,
      visible: false,
      miniprogram: false,
      phone: '',
    }
  },
  created() {
   this.phone=this.$route.query.phone
  },
  mounted() {
   this.ready()
  },
  methods: {
    ready() {
      if (window.__wxjs_environment === 'miniprogram') {
        this.miniprogram = true
        this.getConfig()
      }
    },
    // 配置信息
    getConfig() {
      let url =  window.location.href.split('#')[0]
      const that = this;
      let params={url: url,phone:this.phone};
      axios.get('https://www.funpetfair.com/baseapi/wechat/getJsSdkSign',{params}).then(res => {
        this.loading=false
        res=res.data

        if (res.success){
          that.wxConfig(res.data);
        }else {
          Toast.showToast(res.message, 5000)
        }
      }).catch(err => {
        console.log('err', err)
      })

    },
    wxConfig(result) {
      console.log(result)
      wx.config({
        debug: false, // 开启调试模式,
        appId: 'wx2c6e9ff42c5dc410', // 必填，公众号appid
        timestamp: result.timestamp, // 必填，生成签名的时间戳
        nonceStr: result.nonceStr, // 必填，生成签名的随机串
        signature: result.signature, // 必填，签名
        jsApiList: ['scanQRCode', 'checkJsApi'], // 必填，需要使用的JS接口列表
      });
      wx.ready(() => {
        console.log('配置完成，扫码前准备完成')
      })
      wx.error(function(res) {
        alert('出错了：' + res.errMsg); //wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
      });
    },
    //扫一扫
    doScan() {
      let that=this
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          Api.qrCodeScan({
            activityId:that.activityId,
            openId:that.phone,
            text:result}).then(res => {
            if (res.success){
              Toast.showToast('已为用户激活',2000)
            }else {
              Toast.showToast(res.message,2000)
            }
          });
        }
      });
    }
  },
}
</script>


<style scoped>

.button-class{
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需要 */
  height: 100vh; /* 容器高度 */
  background-size: 100% 100%;
  width: 100%;
}
</style>