<template>
  <div>
    <div v-if="miniprogram">
      <div style="position: fixed;bottom: 10px;z-index: 99">
          <img src="@/assets/images/activity/rules.png" style="width: 50%" @click="goRules">
      </div>
      <div style="position: fixed;bottom: 0px;">
        <div style="width: 60%;margin: 0 auto">
          <!-- 可兑换-->
          <img v-if="!exchangedResult&&exchanged" src="@/assets/images/activity/exchange_color.png" style="width: 100%" @click="toGift">
          <!-- 不可兑换-->
          <img v-if="!exchangedResult&&!exchanged" src="@/assets/images/activity/exchange_grey.png" style="width: 100%" @click="openHallNum">
          <!-- 已兑换-->
          <img v-if="exchangedResult" src="@/assets/images/activity/exchanged_result.png" style="width: 100%">
        </div>
      </div>
      <div v-loading="loading">
        <template v-for="(item,index) in imageList">
          <div :style="{width: '100%',height:bgHeight,backgroundImage:'url('+item.backgroundMap+')',
          backgroundSize:'100% 100%',paddingLeft:item.marginLeft+'%',paddingTop:item.marginTop+'%'}">
            <div v-if="item.exhibitorName" :style="{backgroundImage:'url('+require(item.completed?'@/assets/images/activity/logo_bg_color.png':'@/assets/images/activity/logo_bg_grey.png')+')',
            backgroundRepeat:'no-repeat',backgroundSize:'contain',padding:'5px 10px 15px 8px'}">
              <img :src="item.completed?item.exhibitorLogoColor:item.exhibitorLogoGrey" alt=""
                   :style="{width:logoWidth}"
                   @click="showQrCode(item)">
            </div>
            <div v-if="item.exhibitorName" style="color:#fff;margin-left: -42px;width: 135px;text-align: center;word-wrap: break-word;
              font-size: 12px;text-shadow: 1px 1px 0 #000">
              <b>{{item.exhibitorName+' - '+item.exhibitionHall+'-'+item.hallBooth}}</b>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else style="text-align: center;margin-top: 100px">
      <h3>请使用指定入口打开页面</h3>
    </div>

<!--    展商二维码弹窗-->
    <el-dialog
        :visible.sync="visible"
        :show-close="false" destroy-on-close
        width="80%">
      <div>
        <div style="text-align: center;color: #00ADEF">
          提示：请展商扫描下方二维码激活图标
        </div>
        <div style="margin: 0 auto;width: 250px" id="qrCodeDiv" ref="qrCodeDiv">
          <vue-qr
              :bg-src="curExhibitor.exhibitorLogoColor+'?cache'"
              :size="400"
              :margin="20"
              :correctLevel="3"
              :auto-color="true"
              :dot-scale="0.4"
              :text="curExhibitor.qrCodeText"
          />

<!--          <vue-qr
              :bg-src="require('@/assets/images/img/paper.png')"
              :size="400"
              :margin="20"
              :correctLevel="3"
              :auto-color="true"
              :dot-scale="0.5"
              text='{"couponCode":"0507053177434612"}'
          />-->
        </div>
      </div>
    </el-dialog>
    <!--    兑换奖品二维码弹窗-->
    <el-dialog
        :visible.sync="exchangeVisible"
        :show-close="false" destroy-on-close
        width="80%">
      <div>
        <div style="text-align: center;color: #00ADEF">
          提示：请主办方扫描二维码兑换奖品
        </div>
        <div style="margin: 0 auto;width: 250px" id="qrCodeDiv" ref="qrCodeDiv">
          <vue-qr
              bg-src="https://funpetfair.oss-cn-beijing.aliyuncs.com/app-images/20230728001001.png?cache"
              :size="400"
              :margin="20"
              :correctLevel="3"
              :auto-color="true"
              :dot-scale="0.3"
              :text="urlLink"
          />

        </div>


      </div>
    </el-dialog>
    <!--    查看数量弹窗-->
    <el-dialog
        :visible.sync="hallVisible"
        :show-close="false" destroy-on-close
        width="80%">
      <div>
        <el-card shadow="always">
          <h4>当前集章数量</h4>
          <el-row v-for="(value,key) in hallNum" style="font-size: 18px">
            {{key+' ：'+value}}
          </el-row>
        </el-card>
      </div>
    </el-dialog>
<!--    活动规则弹窗-->
    <el-dialog
        :visible.sync="ruleVisible"
        :show-close="false"
        destroy-on-close
        :modal-append-to-body="false"
        width="95%">

        <el-card :body-style="{ padding: '0px' }">
          <div style="width: 70%;text-align: center;margin: 0 auto">
            <img src="@/assets/images/activity/receive_rule.png" style="width: 100%;" />
          </div>
          <div style="border: 1px solid #c9c9c9;margin: 2vh;border-radius: 5px;padding: 2vh;height: 50vh;overflow-y: auto">
            <div v-html="activity.activityRules"></div>
            <el-divider>当前集章数量</el-divider>
            <el-row v-for="(value,key) in hallNum" style="font-size: 15px">
              {{key+' ：'+value}}
            </el-row>
            <el-divider>奖品信息</el-divider>
            <div v-for="(item,index) in giftList">
              <div class="title">{{index+1}}.{{item.giftName}}
                <span style="font-size: 12px">(剩余:{{item.giftQuantity-item.giftRedeemedQuantity}}个)</span>
              </div>
              <div>
                <el-image
                    style="width: 10%;"
                    :preview-src-list="[item.redeemGiftPictures]"
                    :src="item.redeemGiftPictures"
                    fit="fill"></el-image>
              </div>
              <div class="content">{{item.redemptionGiftInstructions}}</div>
          </div>
          </div>
        </el-card>
    </el-dialog>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import {Api} from "../../utils/api";
import {Message} from "element-ui";
import {Toast} from "../../utils/toast";

export default {
  name: 'signMap',//用户集章地图
  components: {
    VueQr
  },
  data() {
    return {
      progress: 0,
      qrCodeBg: '',
      qrCodeText: '',
      activityId: '',
      activity: {},
      giftList: [],
      curExhibitor: {},
      loading: true,
      exchangeVisible: false,
      ruleVisible: false,
      visible: false,
      completed: false,
      miniprogram: false,
      hallVisible: false,
      urlLink: '',
      phone: '',
      hallNum:{
        "N28":0,
        "N29":0,
        "N30":0,
      },
      exchanged:false,//是否可兑换
      exchangedResult:false,//是否已兑换过礼品
      bgHeight:window.innerWidth*0.5+'px',
      logoWidth:window.innerWidth*0.1+'px',
      imageList: [],
      intervalId:'',
    }
  },
  created() {
    this.phone = this.$route.query.phone
    this.activityId = this.$route.query.activityId


  },
  mounted() {

    this.ready()
    this.intervalId=setInterval(() => {
      //打开二维码并且未激活时才会请求
      if (this.visible&&!this.curExhibitor.completed) {
        this.getResult();
      }
    }, 3000)
  },
  methods: {
    ready() {
      if (window.__wxjs_environment === 'miniprogram') {
        this.miniprogram=true
        Api.exhibitorMap({activityId: this.activityId, phone: this.phone}).then(res => {
          this.loading = false
          this.imageList = res.exhibitorList
          this.exchanged=res.exchanged
          if (res.hallNum){
            this.hallNum=res.hallNum
          }
        });
        Api.activityGiftList({activityId: this.activityId}).then(res => {
          this.activity = res.activityInfo
          this.giftList = res.giftList
        });
        Api.getExchangeCode({activityId: this.activityId, phone: this.phone}).then(res => {
          this.exchangedResult=res.exchanged
          this.urlLink = res.link
        });
      }
    },
    showQrCode(item) {
      if (!item.completed){
        this.curExhibitor = item
        this.visible = true
      }
    },
    getResult() {
      Api.scanResult({activityId: this.activityId, text: this.curExhibitor.qrCodeText}).then(res => {
        if (res.success) {
          Toast.showToast('已激活',2000)
          this.visible = false
          let num = res.data.order - 1
          this.imageList[num] = {
            ...this.imageList[num],
            completed: true,
          }
          this.exchanged=res.data.exchanged
          this.hallNum=res.data.hallNum
          this.$forceUpdate()
          clearInterval(this.intervalId)

        }
      });
    },
    openHallNum(){
      this.hallVisible=true
    },
    toGift(){
      //this.$router.push("/gift?activityId=" + this.activityId + "&phone=" + this.phone);
      if (!this.exchangedResult){
        this.exchangeVisible=true
      }else {
        Toast.showToast('活动期间仅可兑换一次奖品')
      }

    },
    goRules(){
      this.ruleVisible=true
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
}
</script>


<style scoped>
/deep/ .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: #FFF0;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
  box-sizing: border-box;
  width: 50%;
}
.title{
  font-weight: 500;
  font-size: 18px;
  line-height: 35px;
}
.content{
  font-size: 15px;
  line-height: 25px;
}
</style>