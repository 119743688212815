<template>
  <Layout :selectIndex="3">
    <!--轮播图-->
    <carousel />
    <!--日程安排-->
    <div class="rwt-portfolio-area rn-section-gap">
      <div class="container">
        <div style="margin: 0 auto;width: 200px">
          <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
          <h3 style="text-align: center;line-height: 30px;">行业资讯</h3>
        </div>
        <div v-loading="loading">
          <div style="margin: 30px auto 20px;">
            <h4 style="text-align: center;">{{informationData.title}}</h4>
            <p style="text-align: center;">发布时间： {{informationData.releaseDate}}</p>
          </div>
          <div class="row information">
            <div class="col-lg-12" style="color: #333">
              <div v-html="informationData.content"> </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>



<script>
import carousel from  "../components/elements/carousel/carousel";
import Layout from "../components/common/Layout";
import { Api } from '@/utils/api';
export default {
  name: 'InformationDetail',
  components: {carousel,Layout },
  data() {
    return {
      informationData: {},
      loading: false,
      str: ''
    }
  },
  created() {
    this.getData();
  },
  mounted() {
  },
  methods: {
    getData() {
      this.loading = true;
      Api.detailInformation(this.$route.query.index).then(res => {
        this.informationData = res.data
        this.loading = false;
      });
    },
    // inquire(){
    //   let xhr = new XMLHttpRequest()
    //   let okStatus = document.location.protocol === "file:" ? 0 : 200;
    //   let href = '/down/'+this.$route.query.index+'.txt';
    //   console.log(href, 'href');
    //   xhr.open("GET", href, false); // public文件夹下的绝对路径
    //   xhr.overrideMimeType("text/html;charset=utf-8")
    //   xhr.send(null)
    //   this.str = xhr.responseText
    //   console.log(xhr.responseText)  // xhr.responseText为文本中的内容
    // },
  }
}
</script>
<style>
.information p{
  margin: 0px;
  padding: 0px;
  line-height: 40px;
}
</style>
