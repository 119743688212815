/**
 * axios配置
 */
import axios from 'axios';
import {Message} from "element-ui";
axios.defaults.baseURL = '/api'

/* 请求拦截器 */
axios.interceptors.request.use((config) => {
    //console.log(config, 'config');
    return config;
}, (error) => {
    return Promise.reject(error);
});

/* 响应拦截器 */
axios.interceptors.response.use((res) => {
    return res;
}, (error) => {
    // 处理响应错误
    Message.error(error.response.data.message)
});

