<template>
    <ul class="mainmenu" id="test">
      <li style="height: 80px" class="d-none d-lg-block new"
          v-for="item in navList"
          :style="item.id === data.selectIndex?'background: #B656DF':''"
          @click="select(item)"
          :class="item.children.length !==0 ?'has-droupdown has-menu-child-item': ''">
        <router-link v-if="item.children.length === 0" :to="item.to">
            <h5 class="titleH" :style="item.title.length>2?'background-image: url('+item.icon+');background-position: 0% 50%;':'background-image: url('+item.icon+');background-position: 20% 50%;'">{{ item.title }}</h5>
        </router-link>

        <router-link :to="item.to" v-if="item.children.length !== 0">
            <h5 class="titleH" :style="'background-image: url('+item.icon+');background-position: 0% 50%;'">{{ item.title }}</h5>
            <Icon name="plus" class="collapse-icon plus"/>
            <Icon name="minus" class="collapse-icon minus"/>
        </router-link>
        <ul v-if="item.children.length !== 0" class="submenu" style="background: #9618D3;">
          <li v-for="data in item.children">
            <router-link :to="data.to"><span style="color: #fff">{{ data.title }}</span></router-link>
          </li>
        </ul>
      </li>
      <li class="d-block d-lg-none"><router-link to="/">首页</router-link></li>
      <li class="has-droupdown has-menu-child-item d-block d-lg-none">
          <router-link to="/ExhibitionOverview">
            展会介绍
            <Icon name="plus" class="collapse-icon plus"/>
            <Icon name="minus" class="collapse-icon minus"/>
          </router-link>
          <ul class="submenu">
              <li><router-link to="/Organization">组织机构</router-link></li>
              <li><router-link to="/InstitutionalFramework">日程安排</router-link></li>
              <li><router-link to="/ActivityIntroduction">同期活动</router-link></li>
              <li><router-link to="/ScopeExhibits">展品范围</router-link></li>
          </ul>
      </li>
      <li class="has-droupdown has-menu-child-item d-block d-lg-none">
          <router-link to="/ExhibitionService">
              展会服务
              <Icon name="plus" class="collapse-icon plus"/>
              <Icon name="minus" class="collapse-icon minus"/>
          </router-link>
          <ul class="submenu">
            <li><router-link to="/ExhibitionInformation">展会公告</router-link></li>
            <li><router-link to="/exhibitors">展商服务</router-link></li>
            <li><router-link to="/visit">观众服务</router-link></li>
<!--            <li><router-link to="/ExhibitionIntroduction">展商名录</router-link></li>-->
            <li><router-link to="/AdvertisingContribution">广告赞助</router-link></li>
            <li><router-link to="/ExhibitionIntroduction">交通指南</router-link></li>
          </ul>
      </li>
      <li class="d-block d-lg-none"><router-link to="/Information">行业资讯</router-link></li>
      <li class="d-block d-lg-none"><router-link to="/Download">下载中心</router-link></li>
      <li class="d-block d-lg-none"><router-link to="/Contact">联系我们</router-link></li>
    </ul>
</template>

<script>
  import Icon from "../../icon/Icon";
  export default {
    name: 'Nav',
    components: {Icon},
    props: {
      data: {
        default: null
      },
    },
    data() {
      return {
        index: 0,
        navList: [
          {
            title: '首页',
            children: [],
            icon: require('@/assets/images/img/nav/nav1.png'),
            id: 0,
            to: '/'
          },
          {
            title: '展会介绍',
            children: [
              {
                title: '组织机构',
                id: 0,
                to: '/Organization'
              },
              {
                title: '日程安排',
                id: 1,
                to: '/InstitutionalFramework'
              },
              {
                title: '同期活动',
                id: 2,
                to: '/ActivityIntroduction'
              },
              {
                title: '展品范围',
                id: 3,
                to: '/ScopeExhibits'
              },
            ],
            icon: require('@/assets/images/img/nav/nav2.png'),
            id: 1,
            to: '/ExhibitionOverview'
          },
          {
            title: '展会服务',
            children: [
              {
                title: '展会公告',
                id: 0,
                to: '/ExhibitionInformation'
              },
              {
                title: '展商服务',
                id: 1,
                to: '/exhibitors'
              },
              {
                title: '观众服务',
                id: 2,
                to: '/visit'
              },
              // {
              //   title: '展商名录',
              //   id: 2,
              //   to: '/ExhibitionIntroduction'
              // },
              {
                title: '广告赞助',
                id: 3,
                to: '/AdvertisingContribution'
              },
              {
                title: '交通指南',
                id: 4,
                to: '/ExhibitionIntroduction'
              },
            ],
            icon: require('@/assets/images/img/nav/nav3.png'),
            id: 2,
            to: '/ExhibitionService'
          },
          {
            title: '行业资讯',
            children: [],
            icon: require('@/assets/images/img/nav4.png'),
            id: 3,
            to: '/Information'
          },
          {
            title: '下载中心',
            children: [],
            icon: require('@/assets/images/img/nav/nav5.png'),
            id: 4,
            to: '/Download'
          },
          {
            title: '联系我们',
            children: [],
            icon: require('@/assets/images/img/nav/nav6.png'),
            id: 5,
            to: '/Contact'
          }
        ],
      }
    },
    mounted() {
    },
    methods:{
      select(item) {
        this.index = item.id
      }
    }
  }
</script>
<style>
.mainmenu{
  background: #9618D3;
}
.new{
  width: 12.5%;
  text-align: center;
}
.new:hover{
  background: #B656DF;
}
.new li{
  background: #9618D3;
}
.new ul{
  background: #B656DF;
}
.new ul li:hover{
  background: #9618D3;
}
.selectColor{
  background: #B656DF;
}
.titleH{
  width: 100%;
  text-align: left;
  background-repeat: no-repeat;
  color: #fff;
  background-size: 30px;
  line-height: 80px;
  padding-left: 40px;
  text-align: center;
}
</style>
