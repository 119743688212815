<template>
  <div>
    <header style="z-index: 999;width: 100%;background: #fff">
      <div class="container position-relative">
        <div class="row align-items-center row--0">
          <template>
            <div class="col-lg-6 col-md-6 col-4"><Logo/></div>
            <div class="col-lg-4 col-md-6 col-4 d-none d-lg-block"></div>
            <!--电脑-->
            <div class="col-lg-2 col-md-6 col-4 d-none d-lg-block">
              <div style="height: 120px;display:flex;justify-content: space-between;">
                  <router-link to="/exhibitors">
                    <div style="margin-top: 30px">
                      <img class="logo-dark" style="max-height:30px;margin: 0px auto;display: block" src="@/assets/images/img/visit1.png">
                      <span style="margin: 0px;padding: 0px; color: #000">我要参展</span>
                    </div>
                  </router-link>
                  <router-link to="/visit">
                    <div style="margin-top: 30px">
                      <img class="logo-dark" style="max-height:30px;margin: 0px auto;display: block" src="@/assets/images/img/visit2.png">
                      <span style="margin: 0px;padding: 0px; color: #000">我要观展</span>
                    </div>
                  </router-link>
              </div>
            </div>
            <!--手机-->
            <div class="col-lg-5 col-md-6 col-6 d-block d-lg-none">
              <div style="display:flex;justify-content: space-around;">
                <router-link to="/exhibitors">
                  <div style="margin-top: 15px">
                    <img class="logo-dark" style="max-height:15px;margin: 0px auto;display: block" src="@/assets/images/img/visit1.png">
                    <span style="margin: 0px;padding: 0px; color: #000;font-size: 12px;">我要参展</span>
                  </div>
                </router-link>
                <router-link to="/visit">
                  <div style="margin-top: 15px">
                    <img class="logo-dark" style="max-height:15px;margin: 0px auto;display: block" src="@/assets/images/img/visit2.png">
                    <span style="margin: 0px;padding: 0px; color: #000;font-size: 12px;">我要观展</span>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="col-lg-1 col-md-6 col-2 d-block d-lg-none">
              <div class="header-right">
                <button class="hamberger-button"
                        @click.prevent="AppFunctions.toggleClass('.popup-mobile-menu', 'active')">
                  <Icon name="menu" size="21"/>
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
      <nav class="mainmenu-nav d-none d-lg-block" style="width: 100%;margin: 0 auto;background:#9618D3">
        <Nav class="container" :data="data" style="margin: 0 auto;display: flex !important;justify-content: space-between !important;"/>
      </nav>
    </header>
    <MobileMenu/>
    <div>
      <div class="rn-gradient-circle"/>
      <div class="rn-gradient-circle theme-pink"/>
    </div>
  </div>
</template>

<script>
  import Icon from '../../icon/Icon'
  import MobileMenu from './MobileMenu'
  import AppFunctions from '../../../helpers/AppFunctions'
  import Nav from './Nav'
  import Logo from '../../elements/logo/Logo'

  export default {
    name: 'Header',
    props: {
        data: {
            default: null
        },
    },
    components: {Logo, Nav, MobileMenu, Icon},
    data() {
        return {
            AppFunctions
        }
    },
    methods: {
        // toggleStickyHeader() {
        //     const scrolled = document.documentElement.scrollTop;
        //     if (scrolled > 100) {
        //         AppFunctions.addClass('.header-default', 'sticky');
        //     } else if (scrolled <= 100) {
        //         AppFunctions.removeClass('.header-default', 'sticky');
        //     }
        // }
    },
    created() {
        // window.addEventListener('scroll', this.toggleStickyHeader);
    },
    mounted() {
        // this.toggleStickyHeader();
    },
    beforeDestroy() {
        // window.removeEventListener('scroll', this.toggleStickyHeader);
    }
  }
</script>
