<template>
  <div v-loading="loading">
    <div class="notice-area">
      <div class="title">《购买须知》</div>
      <div class="content">
        <div v-for="item in pList">
          <span v-if="titles.indexOf(item) >= 0" style="font-weight: bold; color: #9618D3">{{item}}</span>
          <span v-else>{{item}}</span>
        </div>

        <el-button class="form-button" type="primary" @click="goBack(1)">同意</el-button>
<!--        <el-button class="form-button" style="margin-top: 0;" type="danger" @click="goBack(0)">拒绝</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>

import {Api} from "@/utils/api";

export default {
  name: "SpecialPolicy",
  data() {
    return {
      pList: [],
      titles: ["预约须知：", "进馆路线：", "免票须知：", "参观时间：", "携宠须知：", "进馆须知：", "售后须知："],
      notice: "",
      loading: false,

      ticketBuyerPhone: "",
      validateCode: "",
    }
  },
  mounted() {
    this.ticketBuyerPhone = this.getUrlParam("ticketBuyerPhone");
    this.validateCode = this.getUrlParam("validateCode");
    this.loading = true;
    Api.ticketPurchaseNoticeTopic().then(res => {
      this.loading = false;
      this.notice = res.data;
      let pList = [];
      let pList0 = res.data.split("\\n");
      let length = pList0.length;
      for (let i = 0; i < length; i++) {
        pList = [...pList, ...pList0[i].split("\r\n")];
      }
      this.pList = [...this.pList, ...pList];
    }).catch(() => {
      this.loading = false;
    })
  },
  methods: {
    getUrlParam(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    goBack(checked) {
      this.$route.params.checked = checked;
      this.$route.params.ticketBuyerPhone = this.ticketBuyerPhone;
      this.$route.params.validateCode = this.validateCode;
      this.$router.back();
    },
  }
}

</script>

<style scoped>
.notice-area {
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
  text-align: center;
  color: #9618D3;
}
.content {
  padding: 0 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.content-text {
  /* text-indent: 2em; */
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}

.form-button {
  width: 90vw;
  margin: 10px auto 10px auto;
  background-color: #9618D3;
  color: white;
  height: 40px;
  border-radius: 20px;
  border: none;
}

</style>
