<template>
  <Layout :selectIndex="1">
    <!--轮播图-->
    <carousel />
    <div class="rwt-portfolio-area rn-section-gap">
      <div class="container">
        <div style="margin: 0 auto;width: 200px">
          <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
          <h3 style="text-align: center;line-height: 30px;">组织机构</h3>
        </div>
        <h5 style="color: #9618D3;font-weight: bold;text-align: center;margin: 0px;line-height: 50px;">主办单位</h5>
        <img style="display: block;margin: 0 auto;width: 300px;" src="@/assets/images/img/exhibitionOverview/organization.png">
      </div>
    </div>
  </Layout>
</template>



<script>
import carousel from  "../components/elements/carousel/carousel";
import Layout from "../components/common/Layout";

export default {
  name: 'Organization',
  components: {carousel,Layout },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style>

</style>

