<template>
  <div class="container-audit">
    <div class="container-header">
      <img src="@/assets/images/login/footer.png" />
    </div>

    <span class="container-audit-title">您的申请已发送至主办方!</span>
    <span class="container-audit-content">我们将在7个工作日内完成审核，审核结果将以短信的形式通知，可通过“趣宠会”官方微信公众号 -「观展：专业观众登记」入口随时关注出票情况，并获取二维码电子门票，请勿将电子二维码门票复制转发！</span>
    <span class="container-audit-content">现场将通过电子二维码门票、身份证原件核销入场！</span>

<!--    <el-button class="form-button" type="primary" @click="goBack">返回</el-button>-->
  </div>
</template>

<script>
export default {
  name: "AuditSuccess",
  methods: {
    goBack() {
      this.$router.push("/SpecialHome");
    },
  }
}
</script>

<style scoped>

.container-audit {
  width: 100vw;
  height: 100vh;
  background-color: #f0defb;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-button {
  width: 90vw;
  margin-top: 10px;
}

.container-header {
  background-color: #9801DC;
  width: 100vw;
 }

.container-header img {
  width: 90vw;
  margin: 40px 5vw;
}

.container-audit-title {
  color: #9801DC;
  font-size: 25px;
  font-weight: bold;
  margin-top: 50px;
}
.container-audit-content {
  width: 80vw;
  margin-top: 30px;
  font-size: 16px;
  text-align: center;
}

</style>
