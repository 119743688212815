<template>
  <slide-verify
      :l="40"
      :r="6"
      :w="350"
      :h="170"
      :imgs="picArray"
      :show="false"
      slider-text="向右滑动完成验证"
      ref="slideverify"
      @success="onSuccess"
  ></slide-verify>
</template>

<script>
export default {
  name: "SliderVerify",
  props: ['successFun'],
  data() {
    return {
      //在data中引入`assets`中的图片可以通过`require`的方式来引入
      picArray: [
        require("@/assets/images/img/sliderVerify/img1.jpg"),
        require("@/assets/images/img/sliderVerify/img2.jpg"),
        require("@/assets/images/img/sliderVerify/img3.jpg"),
        require("@/assets/images/img/sliderVerify/img4.jpg"),
        require("@/assets/images/img/sliderVerify/img5.jpg"),
        require("@/assets/images/img/sliderVerify/img6.jpg"),
      ],
    };
  },
  methods: {
    onSuccess() {
      //调用父组件传递过来的验证通过的回调函数
      this.successFun();
    },
    onReset() {
      //重置图片验证组件
      this.$refs.slideverify.reset();
    },
  },
  mounted(){
    this.onReset();
    // 开启侦听一个刷新组件的方法，让登录页每次调用时都刷新一次
    // this.$bus.$on('resetSlideVerify',this.onReset)
  }
};
</script>
