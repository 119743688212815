<template>
    <Layout :selectIndex="2">
        <!--轮播图-->
        <carousel />
        <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <Sponsor/>
            </div>
        </div>
    </Layout>
</template>



<script>
    import carousel from  "../components/elements/carousel/carousel";
    import Sponsor from  "../components/pages/Sponsor";
    import Layout from "../components/common/Layout";

    export default {
        name: 'AdvertisingContribution',
        components: {carousel,Layout, Sponsor },
        data() {
            return {
            }
        },
        mounted() {
        },
        methods: {
        }
    }
</script>
<style>

</style>

