<template>
<!--  菜单3 -->
  <Layout :selectIndex="2">
    <!--轮播图-->
    <carousel />
    <!--合作媒体-->
    <div class="rwt-about-area rn-section-gap">
      <div class="container">
        <div style="margin: 0 auto;width: 200px">
          <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
          <h3 style="text-align: center;line-height: 30px;">展会服务</h3>
        </div>
        <div class="row row--15">
          <div class="col-lg-12">
            <ul class="nav nav-pills rwt-portfolio-filter filter-button-default liststyle mb--20"
                id="pills-tab"
                role="tablist"
                data-aos="fade-up">
              <li
                  :class="{'selectTab': index === activeTab}"
                  style="margin: 0px;padding: 3px 15px;font-size: 15px;border-radius: 5px;"
                  @click="activeTab = index"
                  v-for="(filter, index) in filters"
                  :key="`tab-button-${filter.id}`">
                  {{ filter.text }}
              </li>
            </ul>
          </div>
        </div>
        <div class="row row--15">
          <template v-for="(item, index) in informationData" v-if="activeTab === 0">
            <div class="col-lg-2" style="margin-top: 30px;padding-bottom: 20px;border-bottom: 1px solid #f0f0f0;">
              <img :src="item.image" style="display: block;margin: 0 auto;">
            </div>
            <div class="col-lg-10" style="margin-top: 30px;padding-bottom: 20px;border-bottom: 1px solid #f0f0f0;">
              <router-link :to="'/ExhibitionInformationDetails?index='+item.index">
                <p style="margin: 0px;color: #000;margin-top: 20px;font-size: 18px">{{item.title}}</p>
              </router-link>
              <p style="margin: 0px;font-size: 13px;line-height: 30px;">{{item.data}}</p>
            </div>
          </template>
          <exhibitorsOne v-if="activeTab === 1"/>
          <template v-if="activeTab === 2">
            <h4 style="color: #9618D3;text-align: center;margin-top: 50px;">趣宠会——参展预登记</h4>
            <img style="width: 150px;display: block;margin: 60px auto 20px;" src="@/assets/images/img/exhibitionOverview/code2.png">
            <p style="color: #000;text-align: center;margin: 0px;padding: 0px">扫描关注“趣宠会”公众号</p>
            <p style="color: #000;text-align: center;margin: 10px;padding: 0px">即刻申请门票</p>
          </template>
          <template v-if="activeTab === 3">
            <Sponsor/>
          </template>
          <template v-if="activeTab === 4">
            <img style="display: block;width: 300px;margin: 0 auto;" src="@/assets/images/img/none.jpg">
          </template>
        </div>
      </div>
    </div>
  </Layout>
</template>



<script>
import carousel from  "../components/elements/carousel/carousel"
import Layout from "../components/common/Layout";
import exhibitorsOne from "../components/elements/about/exhibitorsOne";
import Sponsor from  "../components/pages/Sponsor";

export default {
  name: 'ExhibitionService',
  components: {carousel, Layout, exhibitorsOne, Sponsor},
  data() {
    return {
      filters: [
        {
          id: 0,
          text: '展会公告',
        },
        {
          id: 1,
          text: '展商服务',
        },
        {
          id: 2,
          text: '观众服务',
        },
        {
          id: 3,
          text: '广告赞助',
        },
        {
          id: 4,
          text: '交通指南',
        }
      ],
      informationData: [
        {
          data: '2023-12-18',
          index: 1,
          title: '第2届趣宠会暨2024天津国际宠物产业博览会官宣定档！',
          image: require('@/assets/images/img/notice/notice1.png'),
        },
      ],
      activeTab: 0
    }
  }
}
</script>
<style>
.selectTab{
  color: #fff;
  background: #9618D3;
}
</style>
