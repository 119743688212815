import Vue from 'vue'
import VueRouter from 'vue-router'
import Demo from '../views/Demo'
import ExhibitionIntroduction from  '../views/ExhibitionIntroduction'
import ExhibitionInformation from  '../views/ExhibitionInformation'
import ExhibitionInformationDetails from  '../views/ExhibitionInformationDetails'
import ExhibitionOverview from  '../views/ExhibitionOverview'
import ExhibitionService from  '../views/ExhibitionService'
import Exhibitors from  '../views/Exhibitors'
import Contact from  '../views/Contact'
import Information from  '../views/Information'
import Download from '../views/Download'
import Visit from  '../views/Visit'
import InstitutionalFramework from  '../views/InstitutionalFramework'
import ActivityIntroduction from  '../views/ActivityIntroduction'
import Organization from  '../views/Organization'
import ScopeExhibits from  '../views/ScopeExhibits'
import InformationDetail from  '../views/InformationDetail'
import AdvertisingContribution from  '../views/AdvertisingContribution'
import SellerRegister from  '../views/SellerRegister'
import GoWechat from '../views/ticket/index.vue'
import SpecialAudit from "../views/special/SpecialAudit.vue";
import SpecialHome from "../views/special/SpecialHome.vue";
import SpecialCode from "../views/special/SpecialCode.vue";
import AuditSuccess from "../views/special/AuditSuccess.vue";
import SpecialPolicy from "../views/special/SpecialPolicy.vue";
import SignMap from "../views/activity/SignMap";
import Exhibitor from "../views/exhibitor/face";
import ActivityReceive from "../views/activity/ActivityReceive";
import ExhibitorScan from "../views/activity/ExhibitorScan.vue";
import ServiceScan from "../views/activity/ServiceScan.vue";
import GiftList from "../views/activity/GiftListService";

Vue.use(VueRouter)
const title = '第二届趣宠会——暨2024天津国际宠物产业博览会';
const routes = [
    {
        path: '/',
        name: 'Demo',
        component: Demo,
        meta: {
            title: title,
        },
    },
    {
        path: '/exhibitionIntroduction',
        name: 'ExhibitionIntroduction',
        component: ExhibitionIntroduction,
        meta: {
            title: title,
        },
    },
    {
        path: '/ExhibitionInformation',
        name: 'ExhibitionInformation',
        component: ExhibitionInformation,
        meta: {
            title: title,
        },
    },
    {
        path: '/ExhibitionInformationDetails',
        name: 'ExhibitionInformationDetails',
        component: ExhibitionInformationDetails,
        meta: {
            title: title,
        },
    },
    {
        path: '/ExhibitionOverview',
        name: 'ExhibitionOverview',
        component: ExhibitionOverview,
        meta: {
            title: title,
        },
    },
    {
        path: '/ExhibitionService',
        name: 'ExhibitionService',
        component: ExhibitionService,
        meta: {
            title: title,
        },
    },
    {
        path: '/Exhibitors',
        name: 'Exhibitors',
        component: Exhibitors,
        meta: {
            title: title,
        },
    },
    {
        path: '/Contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: title,
        },
    },
    {
        path: '/Information',
        name: 'Information',
        component: Information,
        meta: {
            title: title,
        },
    },
    {
        path: '/Download',
        name: 'Download',
        component: Download,
        meta: {
            title: title,
        },
    },
    {
        path: '/ActivityIntroduction',
        name: 'ActivityIntroduction',
        component: ActivityIntroduction,
        meta: {
            title: title,
        },
    },
    {
        path: '/Organization',
        name: 'Organization',
        component: Organization,
        meta: {
            title: title,
        },
    },
    {
        path: '/ScopeExhibits',
        name: 'ScopeExhibits',
        component: ScopeExhibits,
        meta: {
            title: title,
        },
    },
    {
        path: '/InstitutionalFramework',
        name: 'InstitutionalFramework',
        component: InstitutionalFramework,
        meta: {
            title: title,
        },
    },
    {
        path: '/visit',
        name: 'Visit',
        component: Visit,
        meta: {
            title: title,
        },
    },
    {
        path: '/InformationDetail',
        name: 'InformationDetail',
        component: InformationDetail,
        meta: {
            title: title,
        },
    },
    {
        path: '/AdvertisingContribution',
        name: 'AdvertisingContribution',
        component: AdvertisingContribution,
        meta: {
            title: title,
        },
    },
    {
        path: '/SellerRegister',
        name: 'SellerRegister',
        component: SellerRegister,
        meta: {
            title: title,
        },
    },
    {
        path: '/GoWechat',
        name: 'GoWechat',
        component: GoWechat,
        meta: {
            title: title,
        },
    },
    {
        path: '/SpecialHome',
        name: 'SpecialHome',
        component: SpecialHome,
        meta: {
            title: title,
        },
    },
    {
        path: '/SpecialAudit',
        name: 'SpecialAudit',
        component: SpecialAudit,
        meta: {
            title: title,
        },
    },
    {
        path: '/SpecialCode',
        name: 'SpecialCode',
        component: SpecialCode,
        meta: {
            title: title,
        },
    },
    {
        path: '/AuditSuccess',
        name: 'AuditSuccess',
        component: AuditSuccess,
        meta: {
            title: title,
        },
    },
    {
        path: '/SpecialPolicy',
        name: 'SpecialPolicy',
        component: SpecialPolicy,
        meta: {
            title: title,
        },
    },
    {
        path: '/ticketing/:id',
        name: 'ticketing',
        component: GoWechat,
        meta: {
            title: title,
        },
    },
    {
        path: '/ticketing',
        name: 'ticketing',
        component: GoWechat,
        meta: {
            title: title,
        },
    },
    {
        path: '/signMap',
        name: 'signMap',
        component: SignMap,
        meta: {
            title: '趣宠会——小游戏',
        },
    },
    {
        path: '/exhibitor',
        name: 'exhibitor',
        component: Exhibitor,
        meta: {
            title: title,
        },
    },
    {
        path: '/receive',
        name: 'receive',
        component: ActivityReceive,
        meta: {
            title: '趣宠会——小游戏',
        },
    },
    {
        path: '/exScan',
        name: 'exScan',
        component: ExhibitorScan,
        meta: {
            title: title,
        },
    },
    {
        path: '/serviceScan',
        name: 'serviceScan',
        component: ServiceScan,
        meta: {
            title: title,
        },
    },
    {
        path: '/gift',
        name: 'gift',
        component: GiftList,
        meta: {
            title: title,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    //mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
