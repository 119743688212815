<template>
  <div class="rwt-about-area rn-section-gap">
    <div class="container">
      <div style="margin: 0 auto;width: 200px">
        <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
        <h3 style="text-align: center;line-height: 30px;">合作媒体</h3>
      </div>
      <div class="rwt-brand-area">
        <div class="container">
          <div class="row d-none d-lg-block">
            <div class="col-lg-12 mt--10">
              <ul class="associated">
                <li v-for="(brand, index) in brandList" :key="index">
                    <img class="item" :src="brand.image" alt="Brand Image" @click="hrefA(brand)"/>
                </li>
              </ul>
            </div>
          </div>
          <div class="row d-block d-lg-none">
            <div class="col-lg-12 mt--10">
              <ul class="associated">
                <li v-for="(brand, index) in brandList" :key="index">
                  <img class="item" :src="brand.image" alt="Brand Image" @click="hrefA(brand)"/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutFour',
  data() {
    return {
      brandList: [
        {
          image: require(`@/assets/images/img/index/cooperativeMedia3.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia7.jpg`),
          url: 'http://www.zhongchong365.com/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia4.jpg`),
          url: 'https://www.pethr.com/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia5.jpg`),
          url: 'http://www.petsworld.cn/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia6.jpg`),
          url: 'https://www.zhanhome.com/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia8.jpg`),
          url: 'http://www.xumuzx.com',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia9.jpg`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia10.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia11.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia12.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia13.png`),
          url: 'http://www.foodmate.net/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia14.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia15.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia16.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia17.png`),
          url: '',
        }
      ],
    }
  },
  methods: {
    hrefA(brand) {
      if (brand.url) {
        let e = document.createElement('a');
        e.href = brand.url;
        e.setAttribute("target", "_blank")
        document.body.appendChild(e)
        e.click()
        document.body.removeChild(e)
      }
    },
  }
}
</script>
<style scoped>
  .associated{
    width: 100%;
    list-style: none;
    padding: 0px;
    display: flex;
    justify-items: flex-start;
    flex-wrap: wrap;
  }
  .d-lg-block .associated li {
    width: 16.66%;
    margin-bottom: 20px;
  }
  .associated li .item {
    width: 80%;
    margin: 0 auto;
    display: block;
  }
  .d-lg-none .associated li{
    width: 50%;
  }
</style>
