

var toastTimer;

export class Toast {

    static showToast(msg,duration){
        clearTimeout(toastTimer);

        var ToastBox=document.getElementsByClassName('toastDiv-box')[0];

        if(ToastBox) document.body.removeChild(ToastBox);//防止重复弹出

        duration=isNaN(duration)?3000:duration;

        var m = document.createElement('div');

        m.className = 'toastDiv-box';

        m.innerHTML = msg;

        m.style.cssText="width: 70%;min-width: 150px;opacity: 0.7;height: auto;color: rgb(255, 255, 255);text-align: center;padding: 10px;border-radius: 5px;position: fixed;bottom: 10%;left: 15%;z-index: 999999;background: rgb(0, 0, 0);font-size: 14px;overflow: hidden;";

        document.body.appendChild(m);

        toastTimer=setTimeout(function() {
            var d = 0.5;

            m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in';

            m.style.opacity = '0';

            setTimeout(function() { document.body.removeChild(m); }, d * 1000);

        }, duration);

    }
}