<template>
    <div>
        <footer v-if="data.showFooter">
          <div class="copyright-area copyright-style-one variation-two rn-section-gap">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-3">
                  <img src="@/assets/images/logo/favicon.png" style="width: 60%;display: block;margin: 0 auto;">
                </div>
                <div class="col-lg-5">
                  <ul style="list-style: none;padding: 0px;">
                    <li style="font-size: 14px;color: #9618D3;font-weight: bold;line-height: 40px;">联系我们
                      <ul style="list-style: none;padding: 0px;">
                        <li style="font-size: 13px;color: #9618D3;font-weight: bold;">
                          <img src="@/assets/images/img/footer/icon1.png">
                          <span style="margin-left: 10px;">022-88525566</span>
                        </li>
                        <li style="font-size: 13px;color: #9618D3;font-weight: bold;">
                          <img src="@/assets/images/img/footer/icon2.png">
                          <span style="margin-left: 10px;">www.funpetfair.com</span>
                        </li>
                        <li style="font-size: 13px;color: #9618D3;font-weight: bold;">
                          <img src="@/assets/images/img/footer/icon3.png">
                          <span style="margin-left: 10px;">天津市津南区津南开发区管委会9号楼1楼</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-3">
                  <img src="@/assets/images/img/footer/fotCode.png">
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="background: #9618D3;color: #fff;font-size: 13px;line-height: 50px;text-align: center">
            <div class="col-lg-3"></div>
            <div class="col-lg-2">Copyright©华宠展览（天津）有限公司</div>
            <div class="col-lg-2">备案号：津ICP备2023001697号</div>
            <div class="col-lg-2">
              <img src="@/assets/images/img/footer/bei.png" style="width:20px;padding-bottom: 3px;"/>&nbsp;
              津公网安备 12011202000726号
            </div>
            <div class="col-lg-2"></div>
          </div>
        </footer>
    </div>
</template>

<script>
    import Logo from '../../elements/logo/Logo'

    export default {
        name: 'Footer',
        components: { Logo },
        props: {
            data: {
                default: null
            }
        }
    }
</script>
<style>
.newSize ul li {
  font-size: 14px
}
</style>
