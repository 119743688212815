<template>
  <Layout :selectIndex="3">
<!--    菜单4-->
    <!--轮播图-->
    <carousel />
    <div class="rwt-about-area rn-section-gap">
      <div class="container">
        <div style="margin: 0 auto;width: 200px">
          <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
          <h3 style="text-align: center;line-height: 30px;">{{activeTitle}}</h3>
        </div>
        <div class="row row--15" style="margin-top: 30px;">
          <div class="col-lg-12">
            <ul class="nav nav-pills rwt-portfolio-filter filter-button-default liststyle mb--20"
                id="pills-tab"
                role="tablist"
                data-aos="fade-up">
              <li
                  :class="{'selectTab': index === activeTab}"
                  style="margin: 0px;padding: 3px 15px;font-size: 15px;border-radius: 5px;"
                  v-for="(filter, index) in filters"
                  @click="tabClick(filter)">
                {{ filter.text }}
              </li>
            </ul>
          </div>
        </div>
        <div class="row row--15" v-if="activeTab===0 || activeTab===1">
          <template v-for="(item, index) in informationData">
            <div class="col-lg-2" style="margin-top: 30px;padding-bottom: 20px;border-bottom: 1px solid #f0f0f0;">
              <img :src="'https://www.funpetfair.com/petimg/data/'+item.picture" style="display: block;margin: 0 auto;">
            </div>
            <div class="col-lg-10" style="margin-top: 30px;padding-bottom: 20px;border-bottom: 1px solid #f0f0f0;">
              <a :href="href+'Detail?index='+item.id" target="_blank">
                <p style="margin: 0px;color: #000;margin-top: 30px;font-size: 18px">{{item.title}}</p>
              </a>
              <p style="margin: 0px;font-size: 12px;line-height: 30px;">{{item.releaseDate}}</p>
              <p style="margin: 0px;font-size: 14px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{item.synopsis}}</p>
            </div>
          </template>
          <el-pagination
              style="margin-top: 50px;text-align: right;"
              background
              layout=" prev, pager, next"
              :total="total"
              :page-size="15"
              @current-change="handleCurrentChange">
          </el-pagination>
        </div>
        <div class="row d-none d-lg-block" v-if="activeTab===2">
          <div class="col-lg-12 mt--10">
            <ul class="associated">
              <li v-for="(brand, index) in brandList" :key="index">
                <img class="item" :src="brand.image" alt="Brand Image" @click="hrefA(brand)"/>
              </li>
            </ul>
          </div>
        </div>
        <div class="row d-block d-lg-none" v-if="activeTab===2">
          <div class="col-lg-12 mt--10">
            <ul class="associated">
              <li v-for="(brand, index) in brandList" :key="index">
                <img class="item" :src="brand.image" alt="Brand Image" @click="hrefA(brand)"/>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="activeTab==3">
          <img src="@/assets/images/img/none.jpg" style="margin: 0 auto;display: block;">
        </div>
      </div>
    </div>
  </Layout>
</template>



<script>
import carousel from  "../components/elements/carousel/carousel"
import Layout from "../components/common/Layout";
import { Api } from '@/utils/api';

export default {
  name: 'Information',
  components: {carousel, Layout},
  data() {
    return {
      checkTextAlign: 'center',
      filters: [
        {
          id: 0,
          text: '行业讯息',
        },
        {
          id: 1,
          text: '展会新闻',
        },
        {
          id: 2,
          text: '合作媒体',
        },
        {
          id: 3,
          text: '精彩活动',
        }
      ],
      activeTitle: '行业讯息',
      activeTab: 0,
      newsData: [
      ],
      informationData: [],
      brandList: [
        {
          image: require(`@/assets/images/img/index/cooperativeMedia3.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia7.jpg`),
          url: 'http://www.zhongchong365.com/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia4.jpg`),
          url: 'https://www.pethr.com/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia5.jpg`),
          url: 'http://www.petsworld.cn/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia6.jpg`),
          url: 'https://www.zhanhome.com/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia8.jpg`),
          url: 'http://www.xumuzx.com',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia9.jpg`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia10.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia11.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia12.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia13.png`),
          url: 'http://www.foodmate.net/',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia14.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia15.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia16.png`),
          url: '',
        },
        {
          image: require(`@/assets/images/img/index/cooperativeMedia17.png`),
          url: '',
        }
      ],
      imgUrl: process.env.VUE_APP_API_IMG_URL,
      total: 0,
      href: window.location.href
    }
  },
  mounted() {
  },
  created() {
    this.tabClick({
      id: 0,
      text: '行业讯息',
    })
  },
  methods: {
    hrefA(brand) {
      if (brand.url) {
        let e = document.createElement('a');
        e.href = brand.url;
        e.setAttribute("target", "_blank")
        document.body.appendChild(e)
        e.click()
        document.body.removeChild(e)
      }
    },
    tabClick(e){
      this.activeTab = e.id;
      this.activeTitle = e.text;
      if (e.id == 0 || e.id == 1) {
        Api.pageInformation(e.id, {
          pageNo: 1,
          pageSize: 15
        }).then(res => {
          this.informationData = res.data.rows
          this.total = res.data.totalRows

        });
      }
    },
    handleCurrentChange(e) {
      Api.pageInformation(this.activeTab, {
        pageNo: e,
        pageSize: 15
      }).then(res => {
        this.informationData = res.data.rows
      });
    },
  }
}
</script>
<style scoped>
.selectTab{
  color: #fff;
  background: #9618D3;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #9618D3;
  color: #fff;
}
.el-pagination__total{
  border: 1px solid #f0f0f0;
  background: #f4f4f5;
  padding: 0px 10px;
}
.associated{
  width: 100%;
  list-style: none;
  padding: 0px;
  display: flex;
  justify-items: flex-start;
  flex-wrap: wrap;
}
.d-lg-block .associated li {
  width: 16.66%;
  margin-bottom: 20px;
}
.associated li .item {
  width: 80%;
  margin: 0 auto;
  display: block;

}
.d-lg-none .associated li{
  width: 50%;
}
</style>
