<template>
  <div>
    <div class="mask" v-show="isShowSlide" @click.self="isShowSlide = false">
      <div class="slideContainer">
        <SlideVerify ref="slideblock" :successFun="successVerify"></SlideVerify>
      </div>
    </div>
    <h4 style="color: #9618D3;text-align: center;margin-top: 50px;">趣宠会——展商参展申请表</h4>
    <div class="rwt-about-area rn-section-gap">
      <div class="container">
        <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="公司名称" prop="company">
            <el-input  placeholder="请输入公司名称" v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item label="参展品牌" prop="brand">
            <el-input  placeholder="请输入参展品牌" v-model="form.brand" ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input  placeholder="请输入姓名" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input  placeholder="请输入手机号"  v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="意向面积" prop="area">
            <el-input  placeholder="请输入意向面积"  v-model="form.area"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input placeholder="备注" type="textarea" :rows="4" style="font-size: 17px" v-model="form.remark" ></el-input>
          </el-form-item>
          <el-button style="margin: 0 auto;display: block;background: #9618D3;color: #fff;border: 1px solid #9618D3" @click="submit('ruleForm')">提交</el-button>
        </el-form>
        <div class="row" style="margin-top: 50px;">
          <p style="background: rgb(242,234,245);color: #9618D3;text-align: center;font-weight: bold;line-height: 40px;">参展规则</p>
          <p style="margin: 0px;font-size: 15px;">
            ◇参展申请表经双方签字盖章后即确认作为参展商与展会承办单位所签的参展合同，具有法律效力；<br/>
            ◇本申请表确认后，参展单位需在五个工作日内交纳本合同全部款项，否则原定展位无效；<br/>
            ◇参展商在交付款项后不得临时退或减少展位，否则组委会将取消其参展资格，所交款项不予退还；<br/>
            ◇申请原则：先申请、先付款、先分配；但为保证展会整体形象，组委会保留最终调整展位的权利；<br/>
            ◇参展商在交付全款后，组委会将发出参展确认书、参展商手册，具体安排以手册为准；<br/>
            ◇参展商不得在参展品中展示、销售假冒伪劣商品，不得有侵权行为,不得展出与展会主题无关的产品,如有违反,展品清出展场,所交展位费不予退还。<br/>
            ◇此申请表确认前，参展单位已对本次展会作了全面、深入了解后与展会承办方签订本申请表，并自行承担参展应负的法律与经济风险责任。<br/>
            ◇参展商应遵守中国国家的相关法律、法规、遵守承办单位举办展会的各项规定，如有违反责任自负。<br/>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import SlideVerify from "./SlideVerify";
import { Api } from '@/utils/api';
export default {
  name: 'ExhibitorsOne',
  components: {SlideVerify},
  data() {
    return {
      rules: {
        company: [{required: true, message: '请输入公司名称', type: 'string', trigger: 'change'}],
        brand: [{required: true, message: '请输入参展品牌', type: 'string', trigger: 'change'}],
        name: [{required: true, message: '请输入姓名', type: 'string', trigger: 'change'}],
        phone: [{required: true, message: '请输入手机号', type: 'string', trigger: 'change'}],
        area: [{required: true, message: '请输入意向面积', type: 'string', trigger: 'change'}],
      },
      form:{},
      isShowSlide: false,
    }
  },
  methods: {
    submit(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          console.log(this.form, 'form------')
          this.isShowSlide = true;
          this.$refs.slideblock.onReset();
        } else {
          console.log(valid, 'valid')
          return false;
        }
      });
    },
    successVerify() {
      this.isShowSlide = false;
      Api.addExhibitors(this.form).then(res => {
        this.$message({
          message: '提交成功',
          type: 'success'
        });
        this.form = {}
        this.$refs['ruleForm'].resetFields();
      });
    }
  },
}
</script>
