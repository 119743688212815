<template>
    <main class="page-wrapper">
      <Header :data="$props"/>
<!--      <div class="main-content d-none d-lg-block" style="margin-top: 235px">-->
<!--          <slot></slot>-->
<!--      </div>-->
<!--      <div class="main-content d-block d-lg-none" style="margin-top: 60px">-->
<!--        <slot></slot>-->
<!--      </div>-->
      <div class="main-content">
        <slot></slot>
      </div>
      <Footer v-if="footerStyle === 1" :data="$props"/>
    </main>
</template>

<script>
    import Header from './header/Header'
    import Footer from './footer/Footer'

    export default {
        name: 'Layout',
        components: { Header, Footer},
        props: {
            logo: {
                type: String
            },
            showTopHeader: {
                type: Boolean,
                default: true
            },
            topbarStyle: {
                type: Number,
                default: 1
            },
            showThemeSwitcherButton: {
                type: Boolean,
                default: true
            },
            headerTransparency: {
                type: Boolean,
                default: false
            },
            headerClass: {
                type: String
            },
            navAlignment: {
                type: String,
                default: 'right'
            },
            buyButtonClass: {
                type: String,
                default: 'btn-default btn-small round'
            },
            footerStyle: {
                type: Number,
                default: 1
            },
            showFooter: {
                type: Boolean,
                default: true
            },
            showCopyright: {
                type: Boolean,
                default: true
            },
            showNewsletter: {
                type: Boolean,
                default: false
            },
            showCta: {
                type: Boolean,
                default: true
            },
            selectIndex: {
              type: Number
            }
        }
    }
</script>
