<template>
  <div class="rwt-portfolio-area rn-section-gap">
    <div class="container">
      <div style="margin: 0 auto;width: 200px">
        <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
        <h3 style="text-align: center;line-height: 30px;">展品范围</h3>
      </div>
      <div class="row row--15 service-wrapper">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 pb--20"
             v-for="(service, index) in serviceList"
             :key=index>
          <div
              class="service service__style--1 bg-color-blackest radius text-center"
              data-aos="fade-up"
              style="box-shadow: 0px 0px 5px #ccc;">
            <div class="icon">
              <img style="width: 100%;" :src="service.img" alt="Corporate images"/>
            </div>
            <div class="content">
              <h5 class="title w-600" style="text-align: center;">{{service.title}}</h5>
              <p style="color: #000;height: 110px;text-align: center;">{{service.content}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'OverviewThree',
  components: {},
  props: {
    range: {
      type: Number
    }
  },
  data() {
    return {
      serviceList: [
        {
          img: require('@/assets/images/img/exhibitionOverview/exhibits1.png'),
          title: '宠物食品区',
          content: '猫粮、狗粮、小宠粮、处方粮、湿粮、罐头、零食、冻干、营养补充品、羊奶粉、食品添加剂、原料、食品包装等。'
        },
        {
          img: require('@/assets/images/img/exhibitionOverview/exhibits2.png'),
          title: '宠物用品区',
          content: '宠物服装、宠物床、清洁用品、药用品、保健品、宠物洗浴洗护用品、宠物保养用品、美容用品、玩具、食碗、食盆、饮水器、窝、垫子、笼子等。'
        },
        {
          img: require('@/assets/images/img/exhibitionOverview/exhibits3.png'),
          title: '宠物医疗区',
          content: '宠物药品、宠物医疗器械、宠物营养、宠物疾病诊疗、宠物医院、宠物保险、宠物殡葬，宠物疫苗，宠物克隆等。'
        },
        {
          img: require('@/assets/images/img/exhibitionOverview/exhibits4.png'),
          title: '宠物智能产品区',
          content: '宠物智能舱、宠物智能清洁产品、宠物净化器、宠物智能饮水器、宠物智能喂食器、宠物烘干箱、宠物智能厕所、宠物智能玩具、宠物智能项圈等。'
        },
        {
          img: require('@/assets/images/img/exhibitionOverview/exhibits5.png'),
          title: '名宠展示区',
          content: '猫类、犬类、猪、鼠类、兔、鸟、芦丁鸡、柯尔鸭、蜥蜴、守宫、刺猬、蜜袋鼬、狐獴、浣熊、莽、蛇、貂、蜗牛、蝴蝶、蜘蛛等。'
        },
        {
          img: require('@/assets/images/img/exhibitionOverview/exhibits6.png'),
          title: '水族产业区',
          content: '热带观赏鱼、冷水鱼、虾、蟹、龟、角蛙、寄居蟹等。水族箱、照明器材、过滤系统、鱼缸造景、水生植物、鱼饲料、加热设备、增氧泵、水泵等。'
        },
        {
          img: require('@/assets/images/img/exhibitionOverview/exhibits7.png'),
          title: '文创市集区',
          content: '创意手工艺、宠物艺术周边、特色商家、互动游戏区、宠物手办、宠物装扮、携宠游戏区、跳蚤市场、书画展览等。'
        },
        {
          img: require('@/assets/images/img/exhibitionOverview/exhibits8.png'),
          title: '宠物旅游区',
          content: '房车、宠物推车、旅游景点、便携水壶食碗、背带牵引绳、宠物保暖衣服和脚套、宠物友好民宿、宠物旅游团等。'
        },
      ]
    }
  },
  created() {
    if (this.range === 0) {
      this.serviceList = this.serviceList.splice(0,4);
    }
  },
}
</script>
