<template>
  <div style="background-color: #d954ff;padding: 10px;height: 100vh">
    <div v-if="miniprogram">
      <div v-if="redeemed" style="margin: 10px 0;color: #fff;text-align: center">
        <h1>已兑换过礼品</h1>
      </div>

      <div v-else style="margin: 10px 0" v-for="item in tableData">
        <el-card :body-style="{ padding: '0px' }">
          <el-row :gutter="0" width="100%" style="margin: 0 10px">
            <el-col :span="4" class="card-class">
              <img :src="item.redeemGiftPictures" style="width: 100%;max-height: 100px" alt="">
            </el-col>
            <el-col :span="8" class="card-class">
              {{ item.giftName }}
            </el-col>
            <el-col :span="8" class="card-class">
              <div>
                已兑换：{{ item.giftRedeemedQuantity + '/' + item.giftQuantity }}
              </div>
            </el-col>
            <el-col :span="3" class="card-class">
              <el-popconfirm
                  title="活动期间仅可兑换一次奖品，确认兑换吗？"
                  @confirm="exchange(item)"
              >
                <el-link type="success" :underline="false" slot="reference">兑换</el-link>
              </el-popconfirm>

            </el-col>
          </el-row>
        </el-card>
      </div>
      <div style="margin: 15px 0 0 10px;font-size: 16px;color: #fff">当前用户集章数量</div>
      <el-row v-for="(value,key) in hallNum" style="font-size: 15px;margin: 2px 10px;color: #fff">
        {{key+' ：'+value}}
      </el-row>
    </div>
    <div v-else style="text-align: center;margin-top: 100px">
      <h1>请使用指定入口打开页面</h1>
    </div>
  </div>
</template>

<script>
import {Api} from "../../utils/api";
import {Message} from "element-ui";
import {Toast} from "../../utils/toast";

export default {
  name: 'giftList',//主办方扫描完兑换礼品的二维码后进入的页面

  data() {
    return {
      progress: 0,
      activityId: '',
      userPhone: '',
      servicePhone: '',
      qrCodeBg: '',
      qrCodeText: '',
      hallNum: {},
      loading: false,
      visible: false,
      redeemed: false,
      completed: false,
      miniprogram: false,
      imageList: [],
      tableData: [],
      gift: {},
      intervalId: '',
    }
  },

  mounted() {
    this.userPhone = this.$route.query.userPhone
    this.servicePhone = this.$route.query.servicePhone
    this.activityId = this.$route.query.activityId
    this.ready()
  },
  methods: {
    exchange(item) {
      Api.exchangeGift({giftId: item.id, phone: this.userPhone}).then(res => {
        if (res.success) {
          Toast.showToast('兑换成功', 2000)
          this.visible = false
          this.getData()
        } else {
          Toast.showToast(res.message, 2000)
        }
      });
    },
    ready() {
      if (window.__wxjs_environment === 'miniprogram') {
        this.miniprogram = true
        this.getData()
      }
    },
    getData() {
      this.loading = true
      Api.giftExchangeList({
        activityId: this.activityId,
        userPhone: this.userPhone,
        servicePhone: this.servicePhone
      }).then(res => {
        this.loading = false

        this.tableData = res.list
        this.redeemed = res.redeemed
        this.hallNum = res.hallNum

      });
    },
    showDetail(item) {
      this.gift = item
      this.giftVisible = true
    },
  },
}
</script>

<style scoped>

.card-class {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需要 */
  height: 100px; /* 容器高度 */
}
</style>