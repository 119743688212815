import Request from '@/utils/request';

export class Api {
    static addContact(params) {
        return Request.post('/petDemo/addContact', params);
    }
    static addVisiter(params) {
        return Request.post('/petDemo/addVisiter', params);
    }
    static addExhibitors(params) {
        return Request.post('/petDemo/addExhibitors', params);
    }
    static pageInformation(type,params) {
        return Request.get('/homePet/list/'+type,params);
    }
    static detailInformation(id) {
        return Request.get('/pet/detail/'+id);
    }

    static sellerSendMsg(params) {
        return Request.get('/ticketSeller/senMsg',params);
    }
    static sellerRegister(params) {
        return Request.post('/ticketSeller/register',params);
    }
    static sellerUpdateInfo(params) {
        return Request.post('/ticketSeller/saveInfo',params);
    }

    static ticketBuy(params) {
        return Request.post('/ticketPurchaseInfo/ticketWeb',params);
    }

    static getPrice(params) {
        return Request.getAndLoadData('/ticketPurchaseInfo/getPrice',params);
    }

    //查询订单是否支付成功
    static checkOrder(params) {
        return Request.getAndLoadData('/ticketPurchaseInfo/checkOrder',params);
    }

    static getScheme(params) {
        return Request.getAndLoadData('/ticketPurchaseInfo/getScheme',params);
    }




    static getBillTopicList(params) {
        return Request.getAndLoadData('/informationTopic/getBillTopicList', params);
    }

    static ticketPurchaseInfoWeb(params) {
        return Request.getAndLoadData('/ticketOrderDetails/ticketPurchaseInfoWeb', params);
    }

    static uploadImageWeb(params) {
        return Request.post('/informationTopic/uploadImageWeb', params);
    }

    static specialAuditWeb(params) {
        return Request.post('/informationTopic/specialAuditWeb', params);
    }

    static getOpenidFromOfficialAccount(params) {
        return Request.get('/wechat/getOpenidFromOfficialAccount', params);
    }

    static specialSendSms(params) {
        return Request.post('/wechat/specialSendSms', params);
    }

    static specialCheckSms(params) {
        return Request.post('/wechat/specialCheckSms', params);
    }

    static specialCode(params) {
        return Request.get('/wechat/specialCode', params);
    }

    static ticketPurchaseNoticeTopic(params) {
        return Request.get('/annotatedInfo/ticketPurchaseNoticeTopic', params);
    }

    static specialAuditStatus(params) {
        return Request.get('/wechat/specialAuditStatus', params);
    }

    //获取地图
    static exhibitorMap(params) {
        return Request.getAndLoadData('/activity/exhibitorMap', params);
    }

    //获取扫描结果
    static scanResult(params) {
        return Request.get('/activity/scanResult', params);
    }

    //获取js-sdk签名
    static getJsSdkSign(params) {
        return Request.get('/wechat/getJsSdkSign', params);
    }

    //展商扫描二维码
    static qrCodeScan(params) {
        return Request.get('/activity/qrCodeScan', params);
    }

    //礼品兑换二维码扫描
    static qrCodeScan(params) {
        return Request.get('/activity/qrCodeScan', params);
    }

    //查看当前可兑换礼品
    static giftExchangeList(params) {
        return Request.getAndLoadData('/activity/giftExchangeList', params);
    }

    //活动礼品兑换-主办方核销
    static exchangeGift(params) {
        return Request.get('/activity/exchangeGift', params);
    }

    //查看礼品兑换结果
    static exchangeResult(params) {
        return Request.get('/activity/exchangeResult', params);
    }

    //获取当前可领取的活动
    static releaseActivity(params) {
        return Request.getAndLoadData('/activity/releaseActivity', params);
    }

    //领取活动
    static receiveActivity(params) {
        return Request.get('/activity/receiveActivity', params);
    }

    //查看当前活动详情及礼品
    static activityGiftList(params) {
        return Request.getAndLoadData('/activity/activityGiftList', params);
    }
    //查看当前活动详情及礼品
    static getExchangeCode(params) {
        return Request.getAndLoadData('/activity/getExchangeCode', params);
    }
}
