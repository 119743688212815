<template>
  <div class="rwt-about-area rn-section-gap">
    <div class="container">
      <div style="margin: 0 auto;width: 200px">
        <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
        <h3 style="text-align: center;line-height: 30px;">展会目标</h3>
      </div>
      <div class="row">
        <img style="width: 80%;display: block;margin: 0 auto;" src="@/assets/images/img/exhibitionOverview/goal.png">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OverviewTwo',
  components: {}
}
</script>
