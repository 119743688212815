<template>
  <div class="rwt-about-area rn-section-gap">
    <div class="container">
      <div style="margin: 0 auto;width: 200px">
        <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
        <h3 style="text-align: center;line-height: 30px;">展会概况</h3>
      </div>
      <div class="row row--30 align-items-center">
        <div class="col-lg-7">
          <p style="color: #000;line-height: 35px;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“趣宠会”——  一个超有趣的宠物展！<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;环渤海地区是我国重要的宠物食品、宠物饲料及宠物用品的生产基地和养宠集中地，具有极大的市场商机。天津是我国北方最大的沿海开放城市，北方休闲之都，国际消费中心城市，环渤海地区经济中心，是四大直辖市之一。此外，北方展览规模最大的国家会展中心落地天津，将推动天津会展业持续做大做强。天津“趣宠会”也将也为参展单位占据北方市场带来无限商机，对提升参展单位在全国宠物消费市场的品牌影响具有显著的宣传效应。<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“趣宠会”定位于打造国内“超有趣”的宠博会，立足环渤海，深耕京津冀，聚焦华北，辐射全国，“聚焦宠物经济，推动产业发展”。展会由专业展B2B+大众展B2C组成，打通宠物产业全链条，渠道终端协同联动，携手推动环渤海地区宠物的文化交流与商贸合作。<br/>
          </p>
        </div>
        <div class="col-lg-5">
          <img style="width: 80%;" src="@/assets/images/img/exhibitionOverview/situation.png" >
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OverviewOne',
  components: {}
}
</script>
