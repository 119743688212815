import axios from 'axios';

/**
 * http请求工具
 *
 * 封装axios的get和post方法，方便获取data结果，减少data.data的写法
 *
 * @author fengshuonan
 * @date 2021/4/2 16:09
 */
export default class Request {

    /**
     * 封装get请求
     *
     * @author fengshuonan
     * @date 2021/4/2 16:13
     */
    static async get(url, params) {
        if (params === undefined) {
            params = {};
        }
        let result = await axios.get(url, {params});
        return result.data;
    }

    /**
     * 封装get请求(直接获取请求的结果)
     *
     * @author fengshuonan
     * @date 2021/4/2 16:13
     */
    static async getAndLoadData(url, params) {
        let result = await this.get(url, params);
        return result.data;
    }

    /**
     * 封装post请求
     *
     * @author fengshuonan
     * @date 2021/4/2 16:13
     */
    static async post(url, params) {
        if (params === undefined) {
            params = {};
        }
        let result = await axios.post(url, params);
        console.log(result, '----result')
        return result.data;
    }

    /**
     * 封装post请求
     *
     * @author fengshuonan
     * @date 2021/4/2 16:13
     */
    static async postAndLoadData(url, params) {
        let result = await this.post(url, params);
        return result.data;
    }

}
