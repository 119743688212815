<template>
    <div>
      <div class="logo d-block d-lg-none">
        <router-link :to="url">
          <img class="logo-dark" src="@/assets/images/logo/favicon.png">
        </router-link>
      </div>
      <div class="d-none d-lg-block" style="padding: 20px 0px;">
        <router-link :to="url">
          <img class="logo-dark" style="max-height:78px;width: 200px;" src="@/assets/images/logo/favicon.png">
        </router-link>
      </div>
    </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: {
            url: {
                type: String,
                default: '/'
            },
            lightLogo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/favicon.png`)
                }
            },
            darkLogo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/favicon.png`)
                }
            }
        }
    }
</script>
