<template>
  <Layout :selectIndex="2">
    <div class="mask" v-show="isShowSlide" @click.self="isShowSlide = false">
      <div class="slideContainer">
        <SlideVerify ref="slideblock" :successFun="successVerify"></SlideVerify>
      </div>
    </div>
    <carousel/>
    <exhibitorsOne/>
  </Layout>
</template>

<script>
  import Layout from '../components/common/Layout'
  import exhibitorsOne from "../components/elements/about/exhibitorsOne";
  import carousel from "../components/elements/carousel/carousel";
  export default {
    name: 'Exhibitors',
    components: { Layout,carousel,exhibitorsOne},
    data() {
      return {
      }
    },
    methods: {
    },
  }
</script>
