<template>
  <div>
    <div v-if="miniprogram" v-loading="loading"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0,0,0, 0.6)">

          <div :style="{width: '100%',height:'45vh',backgroundImage:'url('+require('@/assets/images/activity/receive_header.jpg')+')'}" class="background">
            <div :style="{backgroundImage: 'url('+require('@/assets/images/activity/button.png')+')'}" class="button-class">
              <div v-if="received" @click="goMap">
                <b>已领取,点击前往</b>
              </div>
              <div v-else>
                <div @click="receive" >
                  <b>立即领取</b>
                </div>
              </div>
            </div>
          </div>
        <div style="background-color: #9618d3;height: 55vh;padding: 2vh">
            <!-- 活动规则-->
          <el-card :body-style="{ padding: '0px' }">
            <div style="width: 70%;text-align: center;margin: 0 auto">
              <img src="@/assets/images/activity/receive_rule.png" style="width: 100%;" />
            </div>
            <div style="border: 1px solid #c9c9c9;margin: 2vh;border-radius: 5px;padding: 2vh;height: 40vh;overflow-y: auto">
              <div v-html="activityInfo?activityInfo.activityRules:'无进行中的活动'"></div>
              <div v-if="!received" style="color: red;font-weight: 600">
                <div v-if="curRelease">
                  当前时间段可领取数量：{{curRelease.surplusQuantity}}
                </div>
                <div v-if="nextRelease">
                  下一个可领取时间段：<br>{{nextRelease.releaseStartTime+' ~ '+nextRelease.releaseDeadline}}<br>
                  下一个时间段可领取数量：{{nextRelease.plannedReleaseQuantity}}
                </div>
              </div>
              <div>点击<span style="color: #0a58ca;font-weight: 600" @click="init">此处</span>可刷新页面</div>
            </div>
          </el-card>

          </div>

    </div>
    <div v-else style="text-align: center;margin-top: 100px">
      <h1>请使用指定入口打开页面</h1>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import {Api} from "../../utils/api";
import {Toast} from "../../utils/toast";

export default {
  name: 'signMap',//用户领取活动页
  components: {
    VueQr
  },
  data() {
    return {
      buttonLoading:false,
      progress: 0,
      qrCodeBg: '',
      qrCodeText: '',
      activityId: '',
      activityInfo: {},
      curRelease: {surplusQuantity:0},
      nextRelease: {
        releaseStartTime:'',
        releaseDeadline:'',
        plannedReleaseQuantity:0,
      },
      loading: true,
      visible: false,
      miniprogram: false,
      received: true,//是否可领取
      phone: '',
    }
  },
  created() {
  },
  mounted() {
    this.phone = this.$route.query.phone
    this.init()
  },
  methods: {
    init() {
      if (window.__wxjs_environment === 'miniprogram') {
        this.miniprogram=true
        Api.releaseActivity({openId: this.phone}).then(res => {
          this.activityInfo = res.activityInfo
          this.curRelease = res.curRelease//当前可领取
          this.nextRelease = res.nextRelease//下一时段可领取
          this.received = res.received//用户已领取过该活动
          this.loading=false
        });
      }
    },
    goMap(){
      if (this.activityInfo){
        this.$router.push("/signMap?activityId=" + this.activityInfo.id + "&phone=" + this.phone);
      }else
        Toast.showToast('暂无进行中的活动',3000)
    },
    receive(){
      if (!this.activityInfo){
        Toast.showToast('暂无进行中的活动',3000)
        return
      }
      if (!this.curRelease){
        Toast.showToast('当前时段暂无可领取名额',3000)
        return
      }
      this.loading=true
      Api.receiveActivity({releaseId: this.curRelease.id,openId: this.phone}).then(res => {

        this.loading=false
       if (res.success){
         Toast.showToast('领取成功,即将为您跳转到活动页面',2000)
         //alert('领取成功,即将为您跳转到活动页面')
         setTimeout(()=>{
           this.$router.push("/signMap?activityId=" + this.activityInfo.id + "&phone=" + this.phone);
         },2000)

       }
      }).catch(err => {
        this.loading=false
      });
    },

  },

}
</script>


<style scoped>
.background{
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需要 */
  background-size: 100% 100%;
  width: 100%;
}
.button-class{
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需要 */
  background-size: 100% 100%;
  width: 60%;
  height: 15%;
  margin-top: 45%;
  padding-top: -15px;
  font-size: 20px;
  color: #000
}
</style>