<template>
  <Layout :selectIndex="2">
    <div style="text-align: center;line-height: 58vh;font-size:30px;font-weight: bold;">
<!--        暂无内容, 敬请期待！-->
      <img src="@/assets/images/img/none.jpg">
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'

export default {
  name: 'exhibitionIntroduction',
  components: {Layout},
  data() {
    return {
      roleDic: [
        {
          value: 'zhinan',
          label: '指南',
          children: [
            {
              value: 'shejiyuanze',
              label: '设计原则',
              children: [
                {
                  value: 'yizhi',
                  label: '一致',
                  children: [
                      {
                        value: 'a1',
                        label: 'a',
                      },
                      {
                        value: 'b1',
                        label: 'b',
                      },
                      {
                        value: 'c1',
                        label: 'c',
                      }
                  ]
                },
                {
                  value: 'fankui',
                  label: '反馈',
                },
                {
                  value: 'bb',
                  label: 'aa',
                }
              ]
            }
          ]
        },
        {
          value: 'zhinan2',
          label: '指南2',
          children: [
            {
              value: 'shejiyuanze2',
              label: '设计原则2',
              children: [{
                value: 'yizhi2',
                label: '一致2',
              }]
            },
            {
              value: 'shejiyuanze33',
              label: '设计原则3',
            }
          ]
        }
      ],
      seleDic: [],
    }
  },
  created() {
    let arr = [
      ['zhinan', 'shejiyuanze', 'yizhi', 'a1'],
      ['zhinan2', 'shejiyuanze2', 'yizhi2'],
      ['zhinan2', 'shejiyuanze33']
    ]
    for (let i = 0; i < this.roleDic.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        for (let k = 0; k < arr[j].length; k++) {
          if (this.roleDic[i].value == arr[j][k]) {
            this.seleDic[j] = {
              label: this.roleDic[i].label,
              value: j,
              id: arr[j]
            }
            if (this.roleDic[i].children) {
              this.getChild(this.roleDic[i].children,j,arr[j])
            }
          }
        }
      }
    }
    console.log(this.seleDic, 'this.seleDic1')
  },
  methods: {
    getChild(e,j,arr) {
      let child = e;
      let index = j;
      for (let i = 0; i < child.length; i++) {
        this.seleDic[index] = {
          label: this.seleDic[index].label+'/'+child[i].label,
          value: index,
          id: arr
        }
      }
      console.log(this.seleDic, 'this.seleDic2')
    }
  }

}
</script>
