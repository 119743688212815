<template>
  <div class="rwt-about-area rn-section-gap">
    <div class="container">
      <div style="margin: 0 auto;width: 200px">
        <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
        <h3 style="text-align: center;line-height: 30px;">行业资讯</h3>
      </div>
      <div class="row row--30 align-items-center">
        <div class="col-lg-5">
          <div class="thumbnail" data-aos="fade-up" data-aos-delay="250" style="position:relative;">
            <img class="w-100" :src="'https://www.funpetfair.com/petimg/data/'+industryData[0].picture">
            <div style="position:absolute; bottom: 0px;left: 0px;width: 100%;height: 80px;background: rgba(0,0,0,0.3);padding: 10px;">
              <a :href="href+'InformationDetail?index='+industryData[0].id" target="_blank">
                <p class="titleA" style="margin: 0px;color: #fff;display: flex;justify-content: space-between;">
                  <span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{industryData[0].title}}</span>
                  <span style="width: 140px;text-align: right;">{{industryData[0].releaseDate}}</span>
                </p>
              </a>
              <p style="font-size: 12px;color: #fff;margin-top: 10px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{industryData[0].synopsis}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-7 mt_md--40 mt_sm--40">
          <div class="contentRight">
            <div class="item" v-for="(item, index) in informationData" :key="index">
              <div class="time">
                <p class="one">{{item.releaseDate.split('-')[2]}}</p>
                <p class="two">{{item.releaseDate.split('-')[0]+'-'+item.releaseDate.split('-')[1]}}</p>
              </div>
              <div class="title">
                <a :href="href+'InformationDetail?index='+item.id" target="_blank">
                  <p class="one" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.title}}</p>
                </a>
                <p class="two" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.synopsis}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-link to="/Information">
        <p class="moreA" style="line-height: 40px;
        color:#000;
        border-radius:5px;
        margin: 0px auto;
        width: 130px;
        text-align: center;
        margin-top: 40px;
        background: #ccc">查看更多</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { Api } from '@/utils/api';
export default {
  name: 'AboutTwo',
  components: {},
  data() {
    return {
      industryData: [],
      informationData: [],
      href: window.location.href
    }
  },
  mounted() {
  },
  created() {
    this.getList()
  },
  methods: {
    getList(){
      Api.pageInformation(0).then(res => {
        this.industryData = res.data.rows
        this.informationData = res.data.rows.slice(1,5)
      });
    },
  }
}
</script>
<style>
.moreA{
  background: #c5c1c1;
}
.moreA:hover{
  color: #fff !important;
  background: #9618D3;
}
.thumbnail{
  height: 340px;
}
.contentRight {
  height: 340px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.contentRight .item {
  width: 100%;
  padding: 0px;
  overflow: hidden;
  margin: 0px;
}
.contentRight .item p{
  margin: 0px;
}
.contentRight .time{
  height: 70px;
  background: #ccc;
  border-radius: 5px;
  overflow: hidden;
  float: left;
}
.contentRight .time p{
  background: #ccc;
  color: #000;
  text-align: center;
}
.contentRight .time .one{
  font-size: 22px;
  font-weight: bold;
  line-height: 40px;
}
.contentRight .time .two{
  font-size: 12px;
  line-height: 20px;
  padding: 0px 10px;
}
.contentRight .title{
  width: 80%;
  overflow: hidden;
  padding-left: 30px;
  float: left;
}
.contentRight .title .one{
  font-size: 17px;
  color: #000;
  line-height: 40px;
}
.contentRight .title .one:hover{
  color: #9618D3;
}
.contentRight .title .two{
  font-size: 13px;
}
.titleA span:hover{
  color: #9618D3;
}
</style>
