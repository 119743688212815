<template>
  <Layout :selectIndex="1">
    <!--轮播图-->
    <carousel />
    <overview-three :range="1"/>
  </Layout>
</template>



<script>
import carousel from  "../components/elements/carousel/carousel";
import OverviewThree from "../components/elements/exhibitionOverview/OverviewThree";
import Layout from "../components/common/Layout";

export default {
  name: 'ScopeExhibits',
  components: {carousel,Layout,OverviewThree},
}
</script>
<style>

</style>

