<template>
  <Layout :selectIndex="1">
    <!--菜单2-->
    <!--轮播图-->
    <carousel />
    <!--展会概况-->
    <overview-one/>
    <!--展会目标-->
    <overview-two/>
    <!--展会特色-->
    <overview-four/>
    <!--展品范围-->
    <overview-three :range="1"/>
    <!--展会安排-->
    <overview-five/>
  </Layout>
</template>



<script>
import OverviewOne from "../components/elements/exhibitionOverview/OverviewOne";
import OverviewTwo from "../components/elements/exhibitionOverview/OverviewTwo";
import OverviewThree from "../components/elements/exhibitionOverview/OverviewThree";
import OverviewFour from "../components/elements/exhibitionOverview/OverviewFour";
import OverviewFive from "../components/elements/exhibitionOverview/OverviewFive";
import carousel from  "../components/elements/carousel/carousel";
import Layout from "../components/common/Layout";

export default {
  name: 'exhibitionOverview',
  components: {carousel,Layout,OverviewOne,OverviewTwo,OverviewThree,OverviewFour,OverviewFive },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style>

</style>
