<template>
  <Layout :selectIndex="1">
    <!--轮播图-->
    <carousel />
    <div class="rwt-portfolio-area rn-section-gap">
      <div class="container">
        <div style="margin: 0 auto;width: 200px">
          <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
          <h3 style="text-align: center;line-height: 30px;">同期活动</h3>
        </div>
        <div class="row" >
          <div class="col-lg-12">
            <img src="@/assets/images/img/exhibitionOverview/Activity.png">
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>



<script>
import carousel from  "../components/elements/carousel/carousel";
import Layout from "../components/common/Layout";

export default {
  name: 'ActivityIntroduction',
  components: {carousel,Layout },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style>

</style>

