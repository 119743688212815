<template>
  <Layout :selectIndex="5">
<!--    菜单6-->
    <div class="demoBox">
      <!--滑块验证-->
      <div class="mask" v-show="isShowSlide" @click.self="isShowSlide = false">
        <div class="slideContainer">
          <SlideVerify ref="slideblock" :successFun="successVerify"></SlideVerify>
        </div>
      </div>
      <!--轮播图-->
      <carousel />
      <!--展会地点-->
      <div class="rwt-about-area rn-section-gap">
        <div class="container">
          <div style="margin: 0 auto;width: 200px">
            <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
            <h3 style="text-align: center;line-height: 30px;">联系我们</h3>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <!--电脑-->
              <ul class="d-none d-lg-block" style="list-style: none;padding: 0px;width:70%;margin: 0 auto;">
                <li style="display: flex;justify-content: start;" v-for="item in contactData">
                  <div style="height: 40px;width: 40px;background: #9618D3;border-radius: 50%;overflow: hidden;">
                    <img style="width: 20px;height: 20px;display: block;margin: 10px auto;" :src="item.image">
                  </div>
                  <p style="margin-left: 10px;line-height: 40px;color: #000;margin-bottom: 15px;">{{item.name}}</p>
                </li>
              </ul>
              <ul class="d-block d-lg-none" style="list-style: none;padding: 0px;width:100%;margin: 0 auto;">
                <li style="display: flex;justify-content: start;" v-for="item in contactData">
                  <div style="height: 35px;width: 35px;background: #9618D3;border-radius: 50%;overflow: hidden;">
                    <img style="width: 15px;height: 15px;display: block;margin: 10px auto;" :src="item.image">
                  </div>
                  <p style="padding-left: 10px;line-height: 35px;color: #000;margin-bottom: 15px;">{{item.name}}</p>
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <img style="display: block;margin: 0 auto;width: 130px;" src="@/assets/images/img/exhibitionOverview/code2.png">
              <p style="text-align: center;color: #9618D3;font-weight: bold;margin-top: 5px;"><img style="width: 25px;" src="@/assets/images/img/exhibitionOverview/chart.png">&nbsp;&nbsp;&nbsp;趣宠会公众号</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>



<script>
import carousel from  "../components/elements/carousel/carousel"
import Layout from "../components/common/Layout";
import SlideVerify from "../components/elements/about/SlideVerify";
import { Api } from '@/utils/api';

export default {
  name: 'contact',
  components: {carousel, Layout, SlideVerify},
  data() {
    return {
      form:{},
      rules: {
        name: [{required: true, message: '请输入姓名', type: 'string', trigger: 'change'}],
        phone: [{required: true, message: '请输入联系方式', type: 'string', trigger: 'change'}],
      },
      contactData: [
        {
          image: require('@/assets/images/img/contact/icon1.png'),
          name: '电话：022-88525566',
        },
        {
          image: require('@/assets/images/img/contact/icon2.png'),
          name: '邮箱：1920407139@qq.com',
        },
        {
          image: require('@/assets/images/img/contact/icon4.png'),
          name: '地址：天津市津南区津南开发区管委会9号楼1楼',
        },
      ],
      isShowSlide: false,
    }
  },
  methods: {
    submit(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.isShowSlide = true;
          this.$refs.slideblock.onReset();
        } else {
          console.log(valid, 'valid')
          return false;
        }
      });
    },
    successVerify() {
      this.isShowSlide = false;
      Api.addContact(this.form).then(res => {
        this.$message({
          message: '提交成功',
          type: 'success'
        });
        this.form = {}
        this.$refs['ruleForm'].resetFields();
      });
    }
  }
}
</script>
<style>
.mask {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}
.mask .slideContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 380px;
  height: 250px;
  background-color: rgb(255, 255, 255);
}
.sub{
  border: 1px solid #ccc;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  line-height: 40px;color: #808080;
}
.sub:hover{
  background: #9618D3;
  border-color: #9618D3;
  color: #fff !important;
}
.el-form-item__label{
  color: #808080;
}
</style>
