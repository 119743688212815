<template>
  <div class="container-code">

    <div class="container-header">

      <div class="container-header-bg"></div>
      <img class="container-header-ticket" src="@/assets/images/code/header.png" />
      <div class="container-header-code">
<!--        <img :src="form.erCode" @click="previewCode(0)" />-->
<!--        <div style="width: 35vw; height: 35vw;" ref="qrCodeUrl" id="qrCodeUrl"></div>-->
        <div class="container-header-code-canvas-container">
          <canvas class="container-header-code-canvas" ref="canvas"></canvas>
        </div>
        <span>签到码：{{form.signNumberCode}}</span>
        <span style="color: red;">核验时请展示当前页面的动态二维码</span>
      </div>
    </div>

<!--    <img class="container-image" src="@/assets/images/code/lead-time.png"-->
<!--         @click="previewCode(4)"-->
<!--    />-->
    <img class="container-image" src="@/assets/images/code/lead01.png"
         @click="previewCode(1)"
    />
    <img class="container-image" src="@/assets/images/code/lead02.png"
         @click="previewCode(2)"
    />
    <img class="container-image" src="@/assets/images/code/lead03.png"
         @click="previewCode(3)"
    />

    <el-dialog :visible.sync="imageVisible" width="100%" top="0" v-if="imageVisible">
      <div class="preview-bg" @click="previewCode(previewIndex)">
        <img v-if="previewIndex === 0" :src="form.erCode" />
        <img v-if="previewIndex === 1" src="@/assets/images/code/lead01.png" />
        <img v-if="previewIndex === 2" src="@/assets/images/code/lead02.png" />
        <img v-if="previewIndex === 3" src="@/assets/images/code/lead03.png" />
        <img v-if="previewIndex === 4" src="@/assets/images/code/lead-time.png" />
      </div>
    </el-dialog>

  </div>
</template>

<script>

import {Api} from "@/utils/api";
import QRCode from "qrcode";

export default {
  data() {
    return {
      form: {},
      imageVisible: false,
      previewIndex: 0,
      signNumberCode: null,
      timer: null,
      QRCode: "",
    }
  },
  created() {
    // let purchaseId = this.getUrlParam("purchaseId");
    let purchaseId = this.$route.params.purchaseId;
    if (!purchaseId) {
      this.$router.push("/SpecialHome");
      return;
    }
    Api.specialCode({
      id: purchaseId
    }).then(res => {
      this.form = res.data;
      // let code = res.data.erCode.replace("http://39.98.86.166:8887/api", "http://192.168.1.219:8084");
      // console.log(code)
      // this.form.erCode = code;

      this.signNumberCode = res.data.signNumberCode;

      this.generatePic();
      this.timer = setInterval(() => {
        this.generatePic();
      }, 60000)
    })
  },
  methods: {
    generatePic() {
      let params = {
        signNumberCode: this.signNumberCode,
        current: new Date().getTime()
      }
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      QRCode.toDataURL(JSON.stringify(params), {
        errorCorrectionLevel: 'H',
      }, function (err, url) {
        if (err) {
          console.error(err);
        } else {
          const img = new Image();
          img.onload = function () {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          }
          img.src = url;
        }
      })
    },
    getUrlParam(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    goBack() {
      this.$router.back();
    },
    previewCode(previewIndex) {
      this.previewIndex = previewIndex;
      if (previewIndex === 0) { // 不确定剩下的图片是否需要预览
        this.imageVisible = !this.imageVisible;
      }
    },
  }
}

</script>

<style scoped>
.detail-company {
  background-color: #e5d2fd;
  padding: 10px;
  width: calc(100vw - 20px);
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.detail-ercode {
  background-color: white;
  padding: 20px 10px 10px 10px;
  width: calc(100vw - 20px);
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.detail-ercode-image {
  width: 60vw;
  height: 60vw;
  margin-bottom: 10px;
}
.detail-ercode-text {
  line-height: 20px;
  font-size: 12px;
}
.detail-ercode-text-title {
  font-size: 14px;
  font-weight: bold;
}
.detail-content-text-type {
  width: 100%;
}
.detail-content {
  margin-top: 10px;
  background-color: white;
  padding: 10px;
  width: calc(100vw - 20px);
  border-radius: 10px;
  margin-left: 10px;
}
.detail-content:last-child {
  margin-bottom: 20px;
}
.detail-content-text {
  font-size: 12px;
  color: black;
  display: flex;
  flex-direction: column;
}
.detail-content-text-title {
  font-size: 14px;
  color: black;
  font-weight: bold;
}
.detail-content-cell {
  display: flex;
  font-size: 12px;
  margin-top: 10px;
  /* justify-content: center; */
}
.detail-content-cell-left {
  width: 5em;
  color: #999999;
}
.detail-content-cell-right {
  color: #000000;
  display: flex;
  flex-direction: column;
}

.form-button {
  width: 90vw;
  margin: 10px 5vw 50px 5vw;
}


.container-code {
  height: 100vh;
  width: 100vw;
  background-color: #F5E8FC;
  overflow-y: auto;
}
.container-header {
  width: 100vw;
  height: calc(100vw * 647 / 664 + 5vw);
}
.container-header-bg {
  width: 100vw;
  height: 20vw;
  background-color: #9801DC;
}
.container-header-ticket {
  width: 100vw;
  position: relative;
  top: -15vw;
}
.container-header-code {
  position: relative;
  width: 100vw;
  top: -90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-header-code-canvas-container {
  --canvas-container-size: 32vw;
  margin: 5px 0;
  width: var(--canvas-container-size);
  height: var(--canvas-container-size);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://funpetfair.oss-cn-beijing.aliyuncs.com/app-images/tu3.gif");
  background-size: cover;
}
.container-header-code-canvas {
  --canvas-size: 29vw;
  width: var(--canvas-size);
  height: var(--canvas-size);
}
.container-header-code img {
  width: 35vw;
}
.container-header-code span {
  font-size: 14px;
  font-weight: bold;
  color: black;
}
.container-ercode span {
  font-weight: bold;
}
.container-image {
  width: 100vw;
}

.preview-bg {
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-bg img {
  width: 100vw;
}
/deep/ .el-dialog {
  margin: 0;
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 0;
}

</style>
