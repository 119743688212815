<template>
  <div>
<!--    <Layout :footer-style="2">-->
      <router-view/>
<!--    </Layout>-->
  </div>
</template>

<script>
    import Layout from "./components/common/Layout";

    export default {
      name: 'app',
      components: {Layout},
    }
</script>
