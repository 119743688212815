<template>
  <Layout :selectIndex="2">
    <div class="mask" v-show="isShowSlide" @click.self="isShowSlide = false">
      <div class="slideContainer">
        <SlideVerify ref="slideblock" :successFun="successVerify"></SlideVerify>
      </div>
    </div>
    <carousel/>
<!--    <h4 style="color: #9618D3;text-align: center;margin-top: 50px;">趣宠会——参展预登记</h4>
    <img style="width: 150px;display: block;margin: 60px auto 20px;" src="@/assets/images/img/exhibitionOverview/code2.png">
    <p style="color: #000;text-align: center;margin: 0px;padding: 0px">扫描关注“趣宠会”公众号</p>
    <p style="color: #000;text-align: center;margin: 10px;padding: 0px">即刻申请门票</p>-->
    <h4 style="color: #9618D3;text-align: center;margin-top: 50px;">趣宠会——观展购票</h4>
    <p style="color: #000;text-align: center;margin: 0px;padding: 0px">请扫描二维码购票</p>
    <img style="width: 250px;display: block;margin: 20px auto 20px;" src="@/assets/images/img/img.png">
<!--    <div class="rwt-about-area rn-section-gap">
      <div class="container">
        <div style="text-align: center;width: 80%;margin: 0 auto" v-if="showSuccess">
          <img style="width: 90%" src="@/assets/images/register/img-ok.png">
          <div style="color:#803e9d;font-size: 25px">
            购票成功，请在微信搜索“趣宠会”小程序，以当前手机号注册后查看入场二维码
          </div>
        </div>
        <div class="row" v-if="!showSuccess">
          <el-form :model="form" :rules="rules" ref="ruleForm" size="medium"  label-width="180">
            <el-row :gutter="80">
              <el-col class="col-lg-12" style="text-align: center">
                <h3>第二届趣宠会——暨2024天津国际宠物产业博览会 门票</h3>
              </el-col>
              <el-col class="col-lg-12" style="margin-left: 10px">
                <h4>类型：单人单次票</h4>
                <h4>数量：1张</h4>
&lt;!&ndash;                <h4>时间：2023.10.13-15</h4>&ndash;&gt;
                <h4>金额：{{price}}（元）</h4>
              </el-col>
              <el-col class="col-lg-6">
                <el-form-item label="姓名" prop="ticketBuyerName">
                  <el-input  placeholder="请输入姓名"  v-model="form.ticketBuyerName"></el-input>
                </el-form-item>
              </el-col>
&lt;!&ndash;              <el-col class="col-lg-6">
                <el-form-item label="性别" prop="gender">
                  <el-select  v-model="form.gender" placeholder="请选择性别" >
                    <el-option label="男" value="0"></el-option>
                    <el-option label="女" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>&ndash;&gt;
              <el-col class="col-lg-6">
                <el-form-item label="联系电话" prop="ticketBuyerPhone" >
                  <el-input  placeholder="请输入联系电话"   v-model="form.ticketBuyerPhone"></el-input>
                </el-form-item>
              </el-col>
&lt;!&ndash;              <el-col class="col-lg-6">
                <el-form-item label="户籍"  prop="residence">
                  <el-select  v-model="form.residence " placeholder="请选择户籍" style="position: relative">
                    <el-option label="国内户籍" value="0"></el-option>
                    <el-option label="国外户籍" value="1"></el-option>
                    <el-option label="港澳台户籍" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>&ndash;&gt;
              <el-col class="col-lg-6">
                <el-form-item label="证件号" prop="ticketBuyerDocumentNumber">
                  <el-input  placeholder="请输入证件号"   v-model="form.ticketBuyerDocumentNumber"></el-input>
                </el-form-item>
              </el-col>
&lt;!&ndash;              <el-col class="col-lg-6">
                <el-form-item label="参展日期" prop="dateTime">
                  <el-date-picker
                      v-model="form.dateTime"
                      style="width: 100%"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="col-lg-6">
                <el-form-item label="是否携带宠物" prop="bring">
                  <el-select  v-model="form.bring" placeholder="请选择">
                    <el-option label="是" value="0"></el-option>
                    <el-option label="否" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col class="col-lg-12">
                <el-form-item label="备注" prop="remark">
                  <el-input
                      placeholder="请选择活动区域"
                      type="textarea"
                      :rows="4"
                      style="font-size: 17px"
                      v-model="form.remark" ></el-input>
                </el-form-item>
              </el-col>&ndash;&gt;

              <el-col class="col-lg-12">
                <el-button  style="width: 100%;" type="success" plain @click="submit('ruleForm')">立即购票</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div style="width: 100%;text-align: center;margin-top: 40px">
          <el-divider></el-divider>
          <img style="width: 95%" src="@/assets/images/visit/order-detail.jpg">
        </div>

      </div>
    </div>-->
  </Layout>
</template>

<script>
  import Layout from '../components/common/Layout'
  import AboutFour from "../components/elements/about/AboutFour";
  import carousel from "../components/elements/carousel/carousel";
  import SlideVerify from "../components/elements/about/SlideVerify";
  import { Api } from '@/utils/api';
  export default {
    name: 'Exhibitors',
    components: { Layout,AboutFour,carousel,SlideVerify},
    data() {
      return {
        rules: {
          ticketBuyerName: [{required: true, message: '请输入姓名', type: 'string', trigger: 'change'}],
            gender: [{required: true, message: '请选择性别', type: 'string', trigger: 'change'}],
          ticketBuyerPhone: [{required: true, message: '想输入联系电话', type: 'string', trigger: 'change'}],
            residence: [{required: true, message: '请选择户籍', type: 'string', trigger: 'change'}],
          ticketBuyerDocumentNumber: [{required: true, message: '请输入身份证号', type: 'string', trigger: 'change'}],
            dateTime: [{required: true, message: '请选择参展日期', type: 'string', trigger: 'change' }],
            bring: [{required: true, message: '请选择是否携带宠物', type: 'string', trigger: 'change'}],
        },
        form:{},
        isShowSlide: false,
        price:'50',
        orderId:'',
        showSuccess:true,
      }
    },
    mounted() {
      this.init()
    },
    methods:{
      init(){
        Api.getPrice().then(res => {
         this.price=res;
        });
      },
      submit(){
        /*this.$message({
          message: '暂未开通',
          type: 'error'
        });*/
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
           /* console.log(this.form, 'form------')
            this.isShowSlide = true;
            this.$refs.slideblock.onReset();*/
            this.form.payUnitPrice=this.price
            Api.ticketBuy(this.form).then(res => {
              if(res.success){
                this.orderId=res.data.orderId
                localStorage.setItem("orderId",res.data.orderId)
                //跳转到微信支付页面
                window.open(res.data.url,'_blank')
                this.$confirm('请确认是否已完成支付?', '提示', {
                  confirmButtonText: '已完成',
                  cancelButtonText: '取消支付',
                  type: 'warning',
                  center: true
                }).then(() => {
                  //查询是否已成功支付
                  Api.ticketBuy({orderId:this.orderId}).then(res => {
                      if(res){
                        //跳转到支付成功页面，提示用户在微信小程序中查看门票
                        this.showSuccess=true
                      }else {
                        this.$message({
                          type: 'error',
                          message: '未检测到已支付订单，请重新购票！'
                        });
                      }
                  })
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消支付'
                  });
                });
              }else {
                this.$message({
                  message: res.message,
                  type: 'error'
                });
              }


              this.form = {}
              this.$refs['ruleForm'].resetFields();
            });
          } else {
            console.log(valid, 'valid')
            return false;
          }
        });
      },
      successVerify() {
        this.isShowSlide = false;
        Api.addVisiter(this.form).then(res => {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
          this.form = {}
          this.$refs['ruleForm'].resetFields();
        });
      }
    }
  }
</script>
<style>
.mask {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}
.mask .slideContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 380px;
  height: 250px;
  background-color: rgb(255, 255, 255);
}
  .el-input--medium .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 2px;
    width: 100%;
  }
  .el-input--medium {
    font-size: 14px;
    width: 100%;
  }
  .el-select {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  p{
      padding: 0px;
      margin: 0px;
      text-align: left;
  }
</style>
