import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import 'bootstrap/scss/bootstrap.scss';
import 'animate.css';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import './assets/scss/style.scss';
import 'bootstrap';
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueTypedJs from 'vue-typed-js';
import SlideVerify from 'vue-monoplasty-slide-verify';
import './config/axios-config';

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)
Vue.use(ElementUI)
Vue.use(SlideVerify)

new Vue({
  router,
  created () {
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: h => h(App)
}).$mount('#app')
