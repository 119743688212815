<template>
  <div class="rwt-portfolio-area rn-section-gap">
    <div class="container">
      <div style="margin: 0 auto;width: 200px">
        <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
        <h3 style="text-align: center;line-height: 30px;">展会安排</h3>
      </div>
      <div class="row row--15 service-wrapper">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12" v-for="(service, index) in serviceList" :key=index>
          <div class="service service__style--1 bg-color-blackest radius text-center" data-aos="fade-up" style="box-shadow: 0px 0px 5px #ccc;padding: 50px 25px;">
            <div class="icon">
              <img :src="service.img"/>
            </div>
            <div class="content">
              <h5 class="title w-600">{{service.title}}</h5>
              <p
              v-if="service.content"
              style="text-align:center;color: #000;height: 80px;line-height: 35px;margin: 0px;"
              v-html="service.content"></p>
              <img v-else style="width: 80px;height: 80px;" :src="service.qrCode">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OverviewFive',
  components: {},
  data() {
    return {
      serviceList: [
        {
          img: require('@/assets/images/img/exhibitionOverview/arrangement1.png'),
          title: '展会时间：2024年5月24日-26日',
          content: '24日（星期五） 9:00-17:00 观众16:30 停止入场 <br/>25日（星期六） 9:00-17:00 观众16:30 停止入场 <br/>26日（星期日） 9:00-16:00 观众15:00 停止入场'
        },
        {
          img: require('@/assets/images/img/exhibitionOverview/arrangement2.png'),
          title: '展会地点',
          content: '国家会展中心(天津)'
        },
        // {
        //   img: require('@/assets/images/img/exhibitionOverview/arrangement3.png'),
        //   title: '趣宠会小红书',
        //   content: '',
        //   qrCode: require('@/assets/images/img/exhibitionOverview/code1.png')
        // },
        // {
        //   img: require('@/assets/images/img/exhibitionOverview/arrangement4.png'),
        //   title: '趣宠会公众号',
        //   content: '',
        //   qrCode: require('@/assets/images/img/exhibitionOverview/code2.png')
        // },
      ]
    }
  }
}
</script>
<style>
</style>
