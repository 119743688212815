<template>
    <Layout :selectIndex="2">
        <!--轮播图-->
        <carousel />
        <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--15">
                    <template v-for="(item, index) in informationData">
                        <div class="col-lg-2" style="margin-top: 30px;padding-bottom: 20px;border-bottom: 1px solid #f0f0f0;">
                            <img :src="item.image" style="display: block;margin: 0 auto;">
                        </div>
                        <div class="col-lg-10" style="margin-top: 30px;padding-bottom: 20px;border-bottom: 1px solid #f0f0f0;">
                            <router-link :to="'/ExhibitionInformationDetails?index='+item.index">
                                <p style="margin: 0px;color: #000;margin-top: 20px;font-size: 18px">{{item.title}}</p>
                            </router-link>
                            <p style="margin: 0px;font-size: 13px;line-height: 30px;">{{item.data}}</p>
                        </div>
                    </template>
                    <el-pagination
                            style="margin-top: 50px;text-align: right;"
                            background
                            layout=" prev, pager, next"
                            :total="informationData.length">
                    </el-pagination>
                </div>
            </div>
        </div>
    </Layout>
</template>



<script>
    import carousel from  "../components/elements/carousel/carousel"
    import Layout from "../components/common/Layout";

    export default {
        name: 'ExhibitionInformation',
        components: {carousel, Layout},
        data() {
            return {
                informationData: [
                    {
                        data: '2023-12-18',
                        index: 1,
                        title: '第2届趣宠会暨2024天津国际宠物产业博览会官宣定档！',
                        image: require('@/assets/images/img/notice/notice1.png'),
                    },
                ],
            }
        },
        mounted() {
            this.$nextTick(
                // this.getClass()
            )
        },
        created() {
        },
        methods: {
            getClass() {
                let prevStyle = document.getElementsByClassName('btn-prev')[0];
                let prevHtml = prevStyle.getElementsByClassName('el-icon')[0];
                prevHtml.outerHTML = '上一页'
                prevStyle.style.paddingLeft = '10px'
                prevStyle.style.paddingRight = '10px'

                let nextStyle = document.getElementsByClassName('btn-next')[1];
                let nextHtml = nextStyle.getElementsByClassName('el-icon')[0];
                nextHtml.outerHTML = '下一页'
                nextStyle.style.paddingLeft = '10px'
                nextStyle.style.paddingRight = '10px'
            }
        }
    }
</script>
<style>
    .selectTab{
        color: #fff;
        background: #9618D3;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #9618D3;
        color: #fff;
    }
    .el-pagination__total{
        border: 1px solid #f0f0f0;
        background: #f4f4f5;
        padding: 0px 10px;
    }
</style>
